import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import localized from '../../../en.json';
import { SystemHealthType } from '../../../Models/models';
import { BatteryChargingIcon, StartupCountIcon, ThermometerIcon } from '../../../theme/Icons/IshIcons';
import { deviceMonitoringStyles, renderHeading } from '../../../util/DeviceMonitoringUtil';

interface PropsType {
    systemHealth: SystemHealthType | undefined;
    setSignalsSelected: Function;
}

const SystemHealth = (props: PropsType) => {
    const navigate = useNavigate();
    const location = useLocation();
    const assetDetails = location.state;

    const lowerThresholdValue = 79.9;
    const upperThresholdValue = 93.8;

    function getValue(value: number | undefined) {
        return value ?? 0;
    }

    const colorList = ["#007942", "#E2D000", "#DA1E28"];

    const fillColorTemp = (temp: number) => {
        if (temp < lowerThresholdValue) {
            return colorList[0];
        } else if (temp >= lowerThresholdValue && temp <= upperThresholdValue) {
            return colorList[1];
        } else {
            return colorList[2];
        }
    }

    const onClickViewDetails = () => {
        navigate("../datavisualization", {
            state: assetDetails,
        });
        props.setSignalsSelected(["system/health/temperature-core/value", "system/health/temperature-cpu/value", "system/health/battery/value", "system/health/operation-hours/value", "system/health/boot-count/value"]);
    }

    return (
        <Box sx={deviceMonitoringStyles}>
            <Paper elevation={0}>
                {renderHeading(localized["system-health"], onClickViewDetails)}
                <Stack flexDirection='row' justifyContent='space-between' marginTop='16px'>
                    <Box display='flex'>
                        <ThermometerIcon tempHeight={props.systemHealth?.systemTemp?.value}
                            fillColor={fillColorTemp(getValue(props.systemHealth?.systemTemp?.value))}
                            sx={{ width: "39px", height: "114px" }} />
                        <Box display='flex' flexDirection='column'
                            sx={{ marginTop: '49px', marginLeft: '24px', alignItems: 'flex-start' }}>
                            <Typography variant='h2'
                                color={fillColorTemp(getValue(props.systemHealth?.systemTemp?.value))}>{props.systemHealth?.systemTemp?.value}°C</Typography>
                            <Typography variant='body1'>{localized['system-temp']}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex'>
                        <ThermometerIcon tempHeight={props.systemHealth?.cpuTemp?.value}
                            fillColor={fillColorTemp(getValue(props.systemHealth?.cpuTemp?.value))}
                            sx={{ width: "39px", height: "114px" }} />
                        <Box display='flex' flexDirection='column'
                            sx={{ marginTop: '49px', marginLeft: '24px', alignItems: 'flex-start' }}>
                            <Typography variant='h2'
                                color={fillColorTemp(getValue(props.systemHealth?.cpuTemp?.value))}>{props.systemHealth?.cpuTemp?.value}°C</Typography>
                            <Typography variant='body1'>{localized['cpu-temp']}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' sx={{ marginLeft: '24px', alignItems: 'flex-start' }}>
                        <BatteryChargingIcon sx={{ width: "49px", height: "49px" }} />
                        <Typography variant='h2'
                            color="#1B1534">{(getValue(props.systemHealth?.batteryVoltage?.value) / 1000).toFixed(2)} V</Typography>
                        <Typography variant='body1'>{localized['battery-voltage']}</Typography>
                    </Box>
                    <Box display='flex' flexDirection='column' sx={{ marginLeft: '24px', alignItems: 'flex-start' }}>
                        <AccessTimeIcon sx={{ color: "#0057C6", width: "49px", height: "49px" }} />
                        <Typography variant='h2'
                            color="#1B1534">{props.systemHealth?.operatingHours?.value} h</Typography>
                        <Typography variant='body1'>{localized['operating-hours']}</Typography>
                    </Box>
                    <Box display='flex' flexDirection='column' sx={{ marginLeft: '24px', alignItems: 'flex-start' }}>
                        <StartupCountIcon sx={{ width: "49px", height: "49px" }} />
                        <Typography variant='h2' color="#1B1534">{props.systemHealth?.startUpCount?.value}</Typography>
                        <Typography variant='body1'>{localized['startup-count']}</Typography>
                    </Box>
                </Stack>
            </Paper>
        </Box>
    )
}
export default SystemHealth;