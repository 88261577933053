import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import localized from "../../../en.json";
import { BreadCrumbContext } from "../../../store/breadcrumb-context";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import { AssignEdgeDevices } from "./AssignEdgeDevices/AssignEdgeDevices";
import { ProjectDetails } from "./ProjectDetails/ProjectDetails";
import { ProjectMenuList } from "./ProjectMenuList/ProjectMenuList";
import { SitesDetails } from "./SitesDetails/SitesDetails";
import { UserAccess } from "./UserAccess/UserAccess";
import { ProjectInfo } from "../../../Models/models";

export const GET_PROJECT_BY_ID = gql`
  query ($projectID: ID!) {
    getProjectById(projectID: $projectID) {
      name
      description
      createdAt
      customer {
        id
      }
        sites{
        id
        }
    }
  }
`;
export const ProjectManagement = () => {
  const client = useApolloClient();
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>();
  const [projectUpdated, setProjectUpdated] = useState(false);
  const [siteUpdated, setSiteUpdated] = useState(false);
  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  const getProjectById = () => {
    client
      .query({
        query: GET_PROJECT_BY_ID,
        variables: {
          projectID: projectId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setProjectInfo(response.data.getProjectById as ProjectInfo);
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["failed-fetch-project-name"],
          false,
          enqueueSnackbar
        );
      });
  };
  useEffect(() => {
    getProjectById();
  }, [projectId, projectUpdated,siteUpdated]);

  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Engineering",
        link: "/engineering/customer",
      },
      {
        name: projectInfo?.name,
        link: "/engineering/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location, projectInfo?.name]);
  return (
    <Stack
      direction={"row"}
      gap={"30px"}
      height="calc(100vh - 155px)"
      marginLeft={"32px"}
      marginTop={"24px"}
    >
      <Stack>
        <Typography
          variant="h4"
          lineHeight={"34px"}
          sx={{ wordBreak: "break-word", maxWidth: "315px" }}
          marginBottom={"40px"}
        >
          {projectInfo?.name}
        </Typography>
        <ProjectMenuList />
      </Stack>

      <Card
        sx={{
          width: "100%",
          marginRight: "20px",
          height: "97%",
        }}
      >
        <CardContent sx={{ padding: "0px !important", height: "100%" }}>
          <Routes>
            <Route
              path="/sites"
              element={<SitesDetails projectId={projectId} setSiteUpdated={setSiteUpdated}/>}
            />
            <Route
              path="/assignedgedevices"
              element={
                <AssignEdgeDevices
                  projectId={projectId}
                  customerId={projectInfo?.customer?.id}
                />
              }
            />
            <Route path="/manageuseraccess" element={<UserAccess />} />
            <Route
              path="/projectdetails"
              element={
                projectInfo && (
                  <ProjectDetails
                    projectInfo={projectInfo}
                    projectId={projectId}
                    setProjectUpdated={setProjectUpdated}
                  />
                )
              }
            />
          </Routes>
        </CardContent>
      </Card>
    </Stack>
  );
};
