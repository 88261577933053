import { gql, useApolloClient } from "@apollo/client";
import { Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { Asset } from "../../../Models/models";
import localized from "../../../en.json";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import { AssetMonitoringLeftPanel } from "./AssetMonitoringLeftPanel";
import Sld from "./Sld";
import { BreadCrumbContext } from "../../../store/breadcrumb-context";

export const GET_ASSET_BY_ID = gql`
  query ($assetID: Int!) {
    getAssetById(assetID: $assetID) {
      id
      name
      type
      alarm
      warning
      capacity
      capacityUnit
      status
      site {
        project {
          name
        }
      }
      devicesInAsset {
        id
        name
        connectionStatus
      }
    }
  }
`;
export const AssetMonitoring = () => {
  let { assetId } = useParams();
  const client = useApolloClient();
  const [assetDetails, setAssetDetails] = useState<Asset>();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const getAssetById = async () => {
      client
        .query({
          query: GET_ASSET_BY_ID,
          variables: {
            assetID: assetId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: any) => {
          setAssetDetails(response.data.getAssetById);
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-to-fetch-site-details"],
            false,
            enqueueSnackbar
          );
        });
    };
    getAssetById();
  }, [assetId, client, enqueueSnackbar]);

  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Monitor",
        link: "/dashboard/",
      },
      {
        name: assetDetails?.name,
        link: "/dashboard/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location, assetDetails]);

  return (
    <Stack flexDirection="row" sx={{ padding: "24px 32px", gap: "32px" }}>
      <AssetMonitoringLeftPanel assetDetails={assetDetails} />
      <Routes>
        <Route path="/projectsld/*" element={<Sld />} />
        {/* <Route path="/assetmonitoring/*" element={<AssetMonitoringMenu />} /> */}
      </Routes>
    </Stack>
  );
};