import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import localized from "../../../../en.json";
import { ErrorIcon } from "../../../../theme/Icons/IshIcons";

interface PropType {
  open: boolean;
  handleClickFn: () => void;
  actionFunction: Function;
  actionType: string;
  title: string;
  message: string;
}
const ConfirmationDialog = (props: PropType) => {
  const { open, handleClickFn, actionFunction, actionType } = props;
  const handleClose = () => {
    handleClickFn();
  };

  const proceedAction = () => {
    actionFunction();
    handleClickFn();
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        sx={{
          overflowY: "unset",
          overflowX: "unset",
          ".MuiDialogTitle-root": {
            padding: "0 !important",
          },
          ".MuiDialogContent-root ": {
            padding: "0 !important",
          },
          ".MuiIconButton-root ": {
            padding: "0 !important",
          },
          ".MuiDialog-paper ": {
            maxWidth: "620px !important",
          },
        }}
        open={open}
        aria-labelledby="device-status-change-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px 24px",
            }}
          >
            <IconButton
              aria-label="close-icon-button"
              data-testid="closeButton"
              onClick={handleClickFn}
            >
              <CloseIcon
                sx={{
                  color: "#1B1534",
                  width: "35px",
                  height: "35px",
                }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color={"#1B1534"}
            padding={"24px"}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <ErrorIcon
                sx={{
                  marginBottom: "80px !important",
                  width: "60px",
                  height: "60px",
                }}
              />
            </Box>

            <Box>
              <Typography variant="h4" paddingBottom={"4px"}>
                {props.title}
              </Typography>

              <Typography variant="h5" fontWeight={"400"} paddingTop={"16px"}>
                {props.message}
              </Typography>
              <Typography variant="h5" paddingTop={"16px"}>
                {localized["device-status-change-action-message"] +
                  actionType +
                  "?"}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 24px 24px", gap: "8px" }}>
          <Button
            sx={{
              borderRadius: "24px",
              textTransform: "none",
              width: "96px",
              fontSize: "16px",
              fontWeight: "700",
              border:
                "1px solid var(--global-interaction-light-3008-a-00-e-5-default, #8A00E5)",
              padding: "8px 16px",
            }}
            onClick={handleClose}
          >
            <Typography>{localized["cancel-btn"]}</Typography>
          </Button>
          <Button
            sx={{
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "700",
              width: "96px",
              padding: "8px 16px",
              border:
                "1px solid var(--global-interaction-light-3008-a-00-e-5-default, #8A00E5)",
            }}
            variant="contained"
            onClick={proceedAction}
            autoFocus
          >
            <Typography>{localized["proceed-btn"]}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
