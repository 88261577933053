import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import localized from "../../../../../en.json";
import {
  handleNumberInputKeyDown,
  pvPanelParamsWithoutUnit,
  pvPanelParamsWithUnit,
} from "../../../../../util/ConstantUtils";

interface PropTypes {
  assetType: string;
  setPvPanelFormData: any;
  setPvFormDisable: Function;
  initialValues: any;
}

const renderInputLabel = (value: any) => (
  <InputLabel>
    <Typography
      variant="h5"
      sx={{
        color: "#000000",
        span: {
          color: "#DA1E28",
        },
      }}
    >
      {value.title} {!value.optional && <span>*</span>}
    </Typography>
  </InputLabel>
);
const PvTechnicalConfigurationForm = (props: PropTypes) => {
  type FormValues = z.infer<typeof schema>;

  const requiredString = z
    .string()
    .min(1, { message: localized["required-field"] });
  const valueRange0To1Regex = /^0(\.\d+)?$|^1(\.0+)?$/;
  const messageValueRange0To1 = { message: localized["value-range-0-1"] };
  const schema = z.object({
    curtailPercLimit: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    albedo: requiredString.regex(valueRange0To1Regex, messageValueRange0To1),
    moduleStcEfficiency: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    spectralLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    soilingLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    reflectionLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    mismatchLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    dcCablingLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    inverterEfficiencyLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    transformerEfficiencyLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    acCablingLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    otherLosses: requiredString.regex(
      valueRange0To1Regex,
      messageValueRange0To1
    ),
    dateOperationStarted: z.string().optional(),
    manufacturer: z.string().optional(),
    nomPeakPower: requiredString,
    area: requiredString.regex(/^\d*(\.\d+)?$/, {
      message: localized["area-negative-msg"],
    }),
    nomDcVoltage: z.string().optional(),
    nomAcVoltage: z.string().optional(),
    maintenanceCost: z.string().optional(),
    operationalCost: z.string().optional(),
    investmentCost: z.string().optional(),
    modulePlaneAzimuth: requiredString.pipe(
      z.coerce
        .number()
        .gte(0, localized["value-range-6.28"])
        .lte(6.28, localized["value-range-6.28"])
    ),
    modulePlaneInclination: requiredString.pipe(
      z.coerce
        .number()
        .gte(0, localized["value-range-6.28"])
        .lte(6.28, localized["value-range-6.28"])
    ),
    noctCoefficient: requiredString,
    moduleStcPower: requiredString,
    lowerThresholdOfSunElevationForDniCalculation: requiredString,
    stcIrradiation: requiredString,
    stcTemperature: requiredString,
    noctIrradiation: requiredString,
    noctTemperature: requiredString,
    modulePowerTemperatureCoefficient: requiredString.pipe(
      z.coerce
        .number()
        .gte(-1, "Value must be between -1 and 0")
        .lte(0, "Value must be between -1 and 0")
    ),
    area_unit: requiredString,
    nomPeakPower_unit: requiredString,
    nomDcVoltage_unit: z.string().optional(),
    nomAcVoltage_unit: z.string().optional(),
    modulePlaneAzimuth_unit: z.string().optional(),
    modulePlaneInclination_unit: z.string().optional(),
  });

  const {
    register,
    watch,
    reset,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: props.initialValues,
    resolver: zodResolver(schema),
  });
  const formValuesRef = useRef<FormValues | null>(null);

  useEffect(() => {
    if (
      JSON.stringify(formValuesRef.current) !==
      JSON.stringify(props.initialValues)
    ) {
      reset(props.initialValues);
      if (props.initialValues) {
        trigger();
      }
      formValuesRef.current = props.initialValues;
    }
  }, [props.initialValues, reset, trigger]);

  const formValues = watch();
  const isEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(formValues, formValuesRef.current)) {
      formValuesRef.current = formValues;
      props.setPvPanelFormData(formValues);
    }
  }, [formValues, props.setPvPanelFormData]);

  useEffect(() => {
    const isFormValid =
      !isValid || (!isDirty && Object.keys(errors).length > 0);
    props.setPvFormDisable(isFormValid);
  }, [isValid, isDirty, errors]);

  return (
    <Box marginTop="32px" marginLeft="32px">
      <Typography variant="body2" paddingBottom="16px">
        {localized["technical-configuration"]}
      </Typography>
      <form>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="24px">
          {Object.entries(pvPanelParamsWithoutUnit).map(([key, value]) => (
            <Box key={key}>
              {renderInputLabel(value)}
              <Box display="flex" gap="8px">
                <FormControl>
                  <TextField
                    data-testid={key}
                    {...register(key as keyof FormValues)}
                    error={errors[key as keyof FormValues] !== undefined}
                    helperText={errors[key as keyof FormValues]?.message}
                    onKeyDown={value.type === "number" ? handleNumberInputKeyDown : undefined}
                    type={value.type}
                    defaultValue={value.defaultValue}
                    sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                    InputProps={{
                      inputProps: {
                        step: "any",
                        style: {
                          width: "240px",
                          height: "12.5px",
                        },
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap="24px"
          marginTop="24px"
        >
          {Object.entries(pvPanelParamsWithUnit).map(([key, value]) => (
            <Box key={key}>
              {renderInputLabel(value)}
              <Box display="flex" gap="8px">
                <FormControl>
                  <TextField
                    data-testid={key}
                    {...register(key as keyof FormValues)}
                    error={errors[key as keyof FormValues] !== undefined}
                    helperText={errors[key as keyof FormValues]?.message}
                    sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                    type={value.type as any}
                    defaultValue={value.defaultValue}
                    InputProps={{
                      inputProps: {
                        step: "any",
                        style: {
                          width: "240px",
                          height: "12.5px",
                        },
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>

                {value.unitShow && Array.isArray(value.units) && (
                  <FormControl sx={{ width: "120px" }}>
                    <Select
                      data-testid={`${key}_unit`}
                      {...register(`${key}_unit` as any)}
                      defaultValue={
                        props.initialValues
                          ? props.initialValues[`${key}_unit`] ||
                            (value.units.length === 1 ? value.units[0] : "")
                          : value.defaultUnit ||
                            (value.units.length === 1 ? value.units[0] : "")
                      }
                      displayEmpty
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "10px",
                          padding: "10px 8px",
                        },
                        "& .MuiSelect-select": {
                          paddingRight: "24px",
                        },
                      }}
                    >
                      {value.units.length > 1 && (
                        <MenuItem value="" disabled>
                          {localized["unit"]}
                        </MenuItem>
                      )}

                      {value.units.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </form>
    </Box>
  );
};

export default PvTechnicalConfigurationForm;
