import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import SoftwareStoreCard from "./SoftwareStoreCard";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { AccordionDetails, Box, Stack, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Software } from "../../../../Models/models";
import { SoftwareContext } from "../../../../store/software-list-context";
import { SearchIcon } from "../../../../theme/Icons/IshIcons";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../../util/SearchStyleUtil";
import UploadedSoftwareCount from "../../UploadedSoftwareCount/UploadedSoftwareCount";

export const Accordion = styled((accordionProps: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...accordionProps} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled(
  (accordionSummaryProps: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...accordionSummaryProps}
    />
  )
)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const SoftwareStoreAccordion = () => {
  const context = useContext(SoftwareContext);

  const [searchedText, setSearchedText] = React.useState("");
  const [filteredStoredsoftwares, setFilteredStoredsoftwares] = useState<Software[] | undefined>([]);

  const handleChangeSearch = (
    searchedValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchText = searchedValue.target.value;
    setSearchedText(searchText);
    if (context?.softwares) {
      const filteredStoredSoftwares = context?.softwares.filter(
        (softwareDetail: Software) => {
          return softwareDetail.name
            .toLowerCase()
            .includes(searchText.toLowerCase());
        }
      );
      setFilteredStoredsoftwares(filteredStoredSoftwares);
    }else{
      setFilteredStoredsoftwares([]);
    }
  };

  useEffect(() => {
    if (context?.softwares) {
      setFilteredStoredsoftwares(context?.softwares);
    }
  }, [context?.softwares]);

  const sortGroupSoftwareDetails = (
    softwareDetails: Software[] | undefined
  ): Record<string, Software[]> => {
    const groupedSoftwareDetails: Record<string, Software[]> = {};
    softwareDetails!.forEach((software) => {
      const date = new Date(software.uploadedAt);
      const monthYearKey = `${date.toLocaleString("en-US", {
        month: "long",
      })}, ${date.getFullYear()}`;

      groupedSoftwareDetails[monthYearKey] =
        groupedSoftwareDetails[monthYearKey] || [];
      groupedSoftwareDetails[monthYearKey].push(software);
    });
    const sortedSoftwareDetails: Record<string, Software[]> = {};
    Object.keys(groupedSoftwareDetails)
      .sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateB.getTime() - dateA.getTime();
      })
      .forEach((key) => {
        sortedSoftwareDetails[key] = groupedSoftwareDetails[key];
      });

    return sortedSoftwareDetails;
  };

  const sortedGroupedSoftwares = sortGroupSoftwareDetails(filteredStoredsoftwares);

  function getKeyOfFirstElement(sortedAndGroupedData: any) {
    const monthYearKeys = Object.keys(sortedAndGroupedData);
    return monthYearKeys.length > 0 ? monthYearKeys[0] : undefined;
  }

  const firstKey = getKeyOfFirstElement(sortedGroupedSoftwares);
  const [expanded, setExpanded] = useState<string | boolean | undefined>(
    firstKey
  );

  useEffect(() => {
    setExpanded(firstKey);
  }, [firstKey]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Stack >
      <Box padding="24px">
        <Search sx={{ width: "240px !important" }}>
          <SearchIconWrapper>
            <SearchIcon
              sx={{ width: "24px", height: "24px", color: "#5D596E" }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            sx={{ minWidth: "240px" }}
            value={searchedText}
            onChange={(searchText) => handleChangeSearch(searchText)}
          />
        </Search>
      </Box>

      <UploadedSoftwareCount />
      {Object.entries(sortedGroupedSoftwares).map(([key, items]) => (
        <Accordion
          key={key}
          sx={{ background: "#F2F2F2", paddingLeft: "32px" }}
          expanded={expanded === key}
          onChange={handleChange(key)}
        >
          <AccordionSummary sx={{ padding: "0px 0px 0px 0px !important" }}>
            <Stack direction={"row"} width={"100%"}>
              <Typography
                variant="h6"
                sx={{
                  width: "120px",
                  color: "#5D596E",
                  fontSize: "15px",
                  textAlign: "justify",
                }}
              >
                {key}
              </Typography>
              <Divider
                sx={{
                  flexGrow:"1",
                  marginRight:"32px",
                  marginBottom: "10px",
                  marginLeft: "5px",
                  color: "#C0C0C0",
                }}
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {items.map((item) => (
              <SoftwareStoreCard
                key={item.id}
                softwareItem={item}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default SoftwareStoreAccordion;
