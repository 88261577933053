import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import WestIcon from "@mui/icons-material/West";
import {
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { z } from "zod";
import localized from "../../../../../en.json";
import {
  FormFields,
  MapKeyValueObj,
  OpcUaGeneralDetails,
  OpcUaSecurityDetails,
  OpcUaTimeoutDetails,
  SignalRow,
} from "../../../../../Models/models";
import {
  DeleteIcon,
  DownloadIcon,
  ReplaceIcon,
} from "../../../../../theme/Icons/IshIcons";
import {
  opcUaFormFields,
  opcUaFormSchema,
} from "../../../../../util/CommunicationFormConstantUtil";
import { ConfirmationDialog } from "../../../../../util/ConfirmationDialog";
import {
  fileType,
  generalDetailsKeys,
  handleNumberInputKeyDown,
  isAllTrue,
  securityDetailsKeys,
  sheetNames,
  signalPathRegex,
  subscribeNodesKeys,
  timeoutDetailsKeys,
} from "../../../../../util/ConstantUtils";
import {
  commonMethodExcelDataParse,
  compareFunc,
  ErrorMessageDisplay,
  objectData,
} from "../../../../../util/FileUploadUtil";
import { UploadUtil } from "../../../../../util/UploadUtil";
import ShowSnackbar from "../../../../CustomizedSnackbar/ShowSnackbar";
import SignalTable from "../../SignalTable";
import AddNodeDialog from "./AddNodeDialog";
export const GET_DEVICE_DETAIL = gql`
  query ($deviceId: ID!) {
    getDeviceById(deviceId: $deviceId) {
      id
      name
    }
  }
`;
export const CREATE_COMM_PROTOCOL = gql`
  mutation (
    $name: String
    $endpointUrl: String
    $username: String
    $password: String
    $messageSecurityMode: String
    $securityPolicy: String
    $localeIds: String
    $browseCallTimeout: Int
    $discoveryTimeout: Int
    $publishTimeout: Int
    $readCallTimeout: Int
    $sessionTimeout: Int
    $watchdogTimeout: Int
    $writeCallTimeout: Int
    $callCallTimeout: Int
    $reconnectDelay: Int
    $autoReconnect: Boolean
    $persistent: Boolean
    $communicationProtocolID: Int
    $deviceID: String
    $signalDtoReqList: [SignalInput]
  ) {
    createProtocolConfiguration(
      protocolConfigurationDtoReq: {
        parameters: {
          name: $name
          endpointUrl: $endpointUrl
          username: $username
          password: $password
          messageSecurityMode: $messageSecurityMode
          securityPolicy: $securityPolicy
          localeIds: $localeIds
          browseCallTimeout: $browseCallTimeout
          discoveryTimeout: $discoveryTimeout
          publishTimeout: $publishTimeout
          readCallTimeout: $readCallTimeout
          sessionTimeout: $sessionTimeout
          watchdogTimeout: $watchdogTimeout
          writeCallTimeout: $writeCallTimeout
          callCallTimeout: $callCallTimeout
          reconnectDelay: $reconnectDelay
          persistent: $persistent
          autoReconnect: $autoReconnect
        }
        communicationProtocolID: $communicationProtocolID
        deviceID: $deviceID
        signalDtoReqList: $signalDtoReqList
      }
    ) {
      id
    }
  }
`;
export const DOWNLOAD_TEMPLATE = gql`
  query {
    downloadProtocolConfigurationTemplate {
      url
    }
  }
`;

const renderInputLabel = (label: string, required: boolean) => (
  <InputLabel>
    <Typography
      variant="h5"
      sx={{
        paddingBottom: "8px",
        color: "#000000",
        span: {
          color: "#DA1E28",
        },
      }}
    >
      {label} {required && <span style={{ color: "#DA1E28" }}>*</span>}
    </Typography>
  </InputLabel>
);

export const Accordion = styled((accordionProps: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...accordionProps} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled(
  (accordionSummaryProps: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{ fontSize: "0.9rem", color: "#8A00E5" }}
        />
      }
      {...accordionSummaryProps}
    />
  )
)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const relpaceAndDeleteFileBtnStyle = {
  border: "1px solid #8A00E5",
  borderRadius: "24px",
  padding: "8px 16px",
  width: "56px",
  height: "40px",
};

type FormValues = z.infer<typeof opcUaFormSchema>;

const CommunicationProtocolForm = () => {
  const { deviceId } = useParams();
  const { siteId } = useParams();
  const { protocolId } = useParams();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<SignalRow[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const client = useApolloClient();
  const location = useLocation();
  const protocolName = location.state;
  const { enqueueSnackbar } = useSnackbar();
  const [deviceName, setDeviceName] = useState("");
  const [selectedFormFields, setSelectedFormFields] =
    useState<FormFields | null>(null);
  const [isOver, setIsOver] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formDefaultValues, setFormDefaultValues] = useState<FormValues>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(opcUaFormSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleOpenDialog = () => setDialogOpen(true);
  const [isCancelConfirmationDialogOpen, setIsCancelConfirmationDialogOpen] =
    useState<boolean>(false);

  const addNodeHandler = (data: SignalRow) => {
    setTableData((prevData) => [...prevData, data]);
    setDialogOpen(false);
  };

  const deleteNodeHandler = (signal: SignalRow) => {
    const updatedData = tableData.filter(
      (row) => row.path !== signal.path
    );
    setTableData(updatedData);
  };

  const defaultValueTemp: FormValues = {};
  defaultValueTemp["name"] = "";
  defaultValueTemp["endpointUrl"] = "";
  defaultValueTemp["username"] = "";
  defaultValueTemp["password"] = "";

  const renderField = (field: any) => {
    if (field.defaultValue) {
      defaultValueTemp[field.name] = field.defaultValue;
    }
    switch (field.type) {
      case "checkbox":
        return (
          <Box display="flex" alignItems="center" mt={4}>
            <FormControl>
              <Checkbox
                {...register(field.name)}
                color="primary"
                inputProps={{ "aria-labelledby": field.name }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                checked={watch(field.name) || false}
              />
            </FormControl>

            <Box mt={1}>{renderInputLabel(field.label, field.required)}</Box>
          </Box>
        );

      case "dropdown":
        const fieldError = errors[field.name] as FieldError;
        const errorMessage = fieldError ? "Please select a value" : "";
        return (
          <>
            {renderInputLabel(field.label, field.required)}
            <FormControl>
              <Select
                data-testid={field.name}
                {...register(field.name)}
                error={!!errors[field.name]}
                displayEmpty
                defaultValue={field.defaultValue || ""}
                sx={{
                  width: "240px",
                  height: "44px",
                }}
              >
                <MenuItem value="" disabled>
                  <span style={{ color: "#B4B2BC" }}>
                    {field.placeholder || ""}
                  </span>
                </MenuItem>
                {field.dropdownValues?.map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "#DA1E28", margin: "0px" }}>
                {errorMessage}
              </FormHelperText>
            </FormControl>
          </>
        );

      case "text":
      case "number":
        return (
          <>
            {renderInputLabel(field.label, field.required)}
            <FormControl>
              <TextField
                data-testid={field.name}
                type={field.type}
                {...register(field.name)}
                error={!!errors[field.name]}
                onKeyDown={field.type === "number" ? handleNumberInputKeyDown : undefined}
                helperText={errors[field.name]?.message as string}
                name={field.name}
                defaultValue={field.defaultValue || ""}
                placeholder={field.placeholder || ""}
                sx={{
                  ".MuiOutlinedInput-root": {
                    width:
                      field.unit && !!!errors[field.name] ? "168px" : "240px",
                    height: "44px",
                  },
                  ".Mui-error": {
                    margin: "0px ",
                  },
                }}
                InputProps={{ inputProps: { step: "any" } }}
              />
            </FormControl>
          </>
        );

      case "password":
        const fieldErrorPassword = errors[field.name] as FieldError;
        const errorMessagePassword = fieldErrorPassword
          ? `${field.label} is required`
          : "";
        return (
          <>
            {renderInputLabel(field.label, field.required)}
            <FormControl>
              <OutlinedInput
                data-testid={field.name}
                id="device-password"
                type={showPassword ? "text" : "password"}
                placeholder={field.placeholder || ""}
                {...register(field.name)}
                error={!!errors[field.name]}
                sx={{ height: "44px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOff data-testid="visibilityOff" />
                      ) : (
                        <Visibility data-testid="visibilityOn" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  style: {
                    width: "150px",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "22px",
                  },
                }}
              />
              <FormHelperText style={{ color: "#DA1E28", margin: "0px" }}>
                {errorMessagePassword}
              </FormHelperText>
            </FormControl>
          </>
        );
    }
  };

  useEffect(() => {
    const getDeviceDetailById = async () => {
      client
        .query({
          query: GET_DEVICE_DETAIL,
          variables: {
            deviceId: deviceId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setDeviceName(response.data?.getDeviceById.name);
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-to-fetch-device-detail"],
            false,
            enqueueSnackbar
          );
        });
    };
    getDeviceDetailById();
  }, [deviceId, client, enqueueSnackbar]);

  useEffect(() => {
    const protocolFormSelection = (): FormFields | null => {
      const protocolSelection: Record<string, FormFields> = {
        "1": opcUaFormFields,
      };

      return protocolSelection[protocolId || ""] || null;
    };

    setSelectedFormFields(protocolFormSelection());
  }, [protocolId]);

  useEffect(() => {
    reset(formDefaultValues);
    if (formDefaultValues) {
      trigger();
    }
  }, [formDefaultValues, reset]);

  const onSubmit = (data: any) => {
    setIsSubmitted(true);
    const {
      name,
      endpointUrl,
      username,
      password,
      messageSecurityMode,
      securityPolicy,
      localeIds,
      browseCallTimeout,
      discoveryTimeout,
      publishTimeout,
      readCallTimeout,
      sessionTimeout,
      watchdogTimeout,
      writeCallTimeout,
      callCallTimeout,
      reconnectDelay,
      persistent,
      autoReconnect,
    } = data;
    client
      .mutate({
        mutation: CREATE_COMM_PROTOCOL,
        variables: {
          name: name,
          endpointUrl: endpointUrl,
          username: username,
          password: password,
          messageSecurityMode: messageSecurityMode,
          securityPolicy: securityPolicy,
          localeIds: localeIds,
          browseCallTimeout: browseCallTimeout,
          discoveryTimeout: discoveryTimeout,
          publishTimeout: publishTimeout,
          readCallTimeout: readCallTimeout,
          sessionTimeout: sessionTimeout,
          watchdogTimeout: watchdogTimeout,
          writeCallTimeout: writeCallTimeout,
          callCallTimeout: callCallTimeout,
          reconnectDelay: reconnectDelay,
          persistent: persistent,
          autoReconnect: autoReconnect,
          communicationProtocolID: protocolId,
          deviceID: deviceId,
          signalDtoReqList: tableData,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.createProtocolConfiguration.id) {
          ShowSnackbar(
            localized["protocol-saved-success"],
            true,
            enqueueSnackbar
          );
        }
        navigateToDevicesFn();
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["protocol-saved-failed"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      }).finally(() => {
        setIsSubmitted(false);
      });
  };

  const navigateToDevicesFn = () => {
    navigate(`/engineering/site/${siteId}/edgedevices/device/${deviceId}/tab3`);
  };
  const templateDownloadHandler = () => {
    client
      .query({
        query: DOWNLOAD_TEMPLATE,
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        window.location.href =
          res.data.downloadProtocolConfigurationTemplate.url;
        ShowSnackbar(
          localized["template-download-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["template-download-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleFileUploadOnDrop = async (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    setIsOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 1) {
      setErrorMessage(localized["multiple-files-upload-not-supported"]);
      setIsFileUpload(false);
    } else {
      parseExcelFile(droppedFiles[0]);
    }
  };

  const parseExcelFile = (dataFile: File | undefined) => {
    if (!isValidFile(dataFile)) {
      handleInvalidFile();
      return;
    }
    if (dataFile) {
      setUploadedFileName(dataFile.name);
      let reader = new FileReader();
      reader.onload = () => handleFileLoad(reader);
      reader.readAsArrayBuffer(dataFile);
    }
  };

  const isValidFile = (dataFile: File | undefined): boolean => {
    return !!dataFile && fileType.includes(dataFile.type);
  };

  const handleInvalidFile = () => {
    setErrorMessage(localized["invalid-sample-file"]);
    setIsFileUpload(false);
  };

  const handleFileLoad = (reader: FileReader) => {
    const data = reader.result;
    const readedData = XLSX.read(data, { type: "binary" });

    if (!compareFunc(sheetNames, readedData.SheetNames)) {
      handleInvalidExcelFormat();
      return;
    }

    processSheets(readedData);
  };

  const handleInvalidExcelFormat = () => {
    setErrorMessage(localized["invalid-excel-data-format"]);
    setIsFileUpload(false);
  };

  const processSheets = (readedData: XLSX.WorkBook) => {
    const generalDetailsParse = parseSheet(readedData, 0);
    const securityDetailsParse = parseSheet(readedData, 1);
    const timeoutDetailsParse = parseSheet(readedData, 2);
    const subscribeDetailsParse = XLSX.utils.sheet_to_json(
      readedData.Sheets[readedData.SheetNames[3]]
    );

    const generalDetailsData = commonMethodExcelDataParse(generalDetailsParse);
    const securityDetailsData =
      commonMethodExcelDataParse(securityDetailsParse);
    const timeoutDetailsData = commonMethodExcelDataParse(timeoutDetailsParse);

    if (
      isDataValid(
        generalDetailsData,
        securityDetailsData,
        timeoutDetailsData,
        subscribeDetailsParse
      )
    ) {
      setErrorMessage("");
      setIsFileUpload(true);
      processValidData(
        generalDetailsData,
        securityDetailsData,
        timeoutDetailsData,
        subscribeDetailsParse
      );
    } else {
      handleInvalidExcelFormat();
    }
  };

  const parseSheet = (readedData: XLSX.WorkBook, index: number) => {
    return XLSX.utils.sheet_to_json(
      readedData.Sheets[readedData.SheetNames[index]],
      { header: 1 }
    );
  };

  const isDataValid = (
    generalDetailsData: MapKeyValueObj,
    securityDetailsData: MapKeyValueObj,
    timeoutDetailsData: MapKeyValueObj,
    subscribeDetailsParse: unknown[]
  ) => {
    const isSubscribeDetailsValid =
      subscribeDetailsParse.length === 0
        ? true
        : compareFunc(
          subscribeNodesKeys,
          Object.keys(subscribeDetailsParse[0] as Record<string, any>).filter(
            (header) => !header.startsWith("__EMPTY")
          )
        );
    const isSignalDataValid = validateSignalData(subscribeDetailsParse);
    return isAllTrue(
      compareFunc(generalDetailsKeys, objectData(generalDetailsData)),
      compareFunc(securityDetailsKeys, objectData(securityDetailsData)),
      compareFunc(timeoutDetailsKeys, objectData(timeoutDetailsData)),
      isSubscribeDetailsValid,
      isSignalDataValid
    );
  };

  const validateSignalData = (subscribeDetailsParse: unknown[]): boolean => {
    return subscribeDetailsParse.every(
      (subscribeDetailsData: any) =>
        typeof subscribeDetailsData["Polling time"] === "number" &&
        typeof subscribeDetailsData["Threshold"] === "number" &&
        typeof subscribeDetailsData["Unit"] === "string" &&
        typeof subscribeDetailsData["Node Path"] === "string" &&
        signalPathRegex.test(subscribeDetailsData["Node Path"]) &&
        typeof subscribeDetailsData["Tag Name"] === "string"
    );
  };

  const processValidData = (
    generalDetailsData: MapKeyValueObj,
    securityDetailsData: MapKeyValueObj,
    timeoutDetailsData: MapKeyValueObj,
    subscribeDetailsParse: unknown[]
  ) => {
    const opcuaProtocolConfiguration = createOpcUaConfiguration(
      generalDetailsData,
      securityDetailsData,
      timeoutDetailsData
    );
    setFormDefaultValues(opcuaProtocolConfiguration);
    setTableData(createSignalList(subscribeDetailsParse));
  };

  const createOpcUaConfiguration = (
    generalDetailsData: MapKeyValueObj,
    securityDetailsData: MapKeyValueObj,
    timeoutDetailsData: MapKeyValueObj
  ) => {
    const generalDetails: OpcUaGeneralDetails = {
      name: generalDetailsData["Session Name"],
      endpointUrl: generalDetailsData["Endpoint URL"],
      username: generalDetailsData["User"],
      password: generalDetailsData["Password"],
    };

    const securityDetails: OpcUaSecurityDetails = {
      messageSecurityMode: securityDetailsData["Message Security Mode"],
      securityPolicy: securityDetailsData["Security Policy"],
      localeIds: securityDetailsData["Locale IDs"],
    };

    const timeoutDetails: OpcUaTimeoutDetails = {
      browseCallTimeout: timeoutDetailsData["Browse Timeout"],
      discoveryTimeout: timeoutDetailsData["Discovery Timeout"],
      publishTimeout: timeoutDetailsData["Publish Timeout"],
      readCallTimeout: timeoutDetailsData["Read Timeout"],
      sessionTimeout: timeoutDetailsData["Session Timeout"],
      watchdogTimeout: timeoutDetailsData["Watchdog Timeout"],
      writeCallTimeout: timeoutDetailsData["Write Timeout"],
      callCallTimeout: timeoutDetailsData["Call Timeout"],
      reconnectDelay: timeoutDetailsData["Reconnect"],
      persistent: timeoutDetailsData["Persistent"] === "Yes",
      autoReconnect: timeoutDetailsData["Auto Reconnect"] === "Yes",
    };

    return { ...generalDetails, ...securityDetails, ...timeoutDetails };
  };

  const createSignalList = (subscribeDetailsParse: unknown[]): SignalRow[] => {
    const uniquePaths = new Set<string>();
    const signalList: SignalRow[] = [];

    subscribeDetailsParse.forEach((subscribeDetailsData: any) => {
      const nodePath = subscribeDetailsData["Node Path"];

      if (!uniquePaths.has(nodePath)) {
        uniquePaths.add(nodePath);
        signalList.push({
          displayName: subscribeDetailsData["Tag Name"],
          path: nodePath,
          unit: subscribeDetailsData["Unit"],
          pollingTime: subscribeDetailsData["Polling time"],
          threshold: subscribeDetailsData["Threshold"],
        });
      }
    });

    return signalList;
  };
  const handleFileUploadOnClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const dataFile = event.target.files?.[0];
    parseExcelFile(dataFile);
    event.target.value = "";
  };

  const handleReplaceUploadedFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteUploadedFile = () => {
    reset(defaultValueTemp);
    setIsFileUpload(false);
    setTableData([]);
  };

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Box
        sx={{ marginTop: "24px", marginLeft: "32px", marginBottom: "32px" }}
        display="flex"
        alignItems="center"
      >
        <IconButton
          data-testid="backBtn"
          sx={{ marginRight: "24px" }}
          onClick={() => {
            navigateToDevicesFn();
          }}
        >
          <WestIcon
            fontSize="small"
            style={{ height: "24px", width: "24px", color: "#8A00E5" }}
          ></WestIcon>
        </IconButton>
        <Typography variant="h4" fontSize="22px">
          {"New " + protocolName + localized["configuration"]}
        </Typography>
      </Box>
      <Box
        sx={{
          maxHeight: "calc(100vh - 408px)",
          overflowY: "auto",
          scrollbarWidth: "thin",
          height: "calc(100vh - 408px)",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            fontSize="20px"
            marginLeft="32px"
            marginBottom="16px"
          >
            Device Name : {deviceName}
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom="16px"
          paddingRight={"32px"}
        >
          <Typography fontSize="20px" lineHeight={"28px"} marginLeft="32px">
            {localized["import-file"]}
          </Typography>
          <Button
            disableRipple
            data-testid="download-template"
            sx={{
              alignItems: "center",
              borderRadius: "24px",
              padding: "8px 16px",
              height: "40px",
              width: "254px",
            }}
            onClick={templateDownloadHandler}
            startIcon={<DownloadIcon sx={{ width: "24px", height: "24px" }} />}
          >
            <Typography
              sx={{
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              {localized["download-excel-template"]}
            </Typography>
          </Button>
        </Stack>
        <Box
          display="flex"
          data-testid="excel-file-dropzone"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleFileUploadOnDrop}
          sx={{
            height: "111px",
            backgroundColor: "rgba(185, 108, 255, 0.10)",
            borderRadius: "10px",
            border: "1px dashed #8A00E5",
            marginLeft: "32px",
            marginRight: "32px",
            textAlign: "center",
            paddingBottom: "5px",
            paddingTop: "5px",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {!isFileUpload ? (
            <Box>
              <Button
                data-testid="upload-excel-input-btn"
                component="label"
                role={undefined}
                variant="contained"
                sx={{
                  height: "30px",
                  backgroundColor: isOver
                    ? "lightgray !important"
                    : "white !important",
                  transition: "background-color 0.3s",
                  borderRadius: "4px",
                  border: "1px solid #C0C0C0",
                  background: "#FFF",
                  marginTop: "18.5px",
                  padding: "8px 24px 8px 24px",
                  "&:hover": {
                    background: "#FFF !important",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#1B1534",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                >
                  {localized["select-file-button"]}
                </Typography>
                <input
                  id="upload-file"
                  data-testid="excel-file-upload-input"
                  type="file"
                  hidden
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileUploadOnClick}
                />
              </Button>
              <Typography
                variant="body1"
                sx={{
                  color: "#1B1534",
                  fontSize: "12px",
                  paddingTop: "16px",
                }}
              >
                {localized["supported-file-text"]}
              </Typography>
              {errorMessage && <ErrorMessageDisplay errorMsg={errorMessage} />}
            </Box>
          ) : (
            <Box
              display="flex"
              sx={{
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                gap: "16px",
              }}
            >
              <Box display="flex" sx={{ alignItems: "center", gap: "8px" }}>
                <UploadUtil fileName={uploadedFileName} />
              </Box>
              <input
                id="upload-file-replace"
                data-testid="excel-file-upload-input-replace"
                ref={fileInputRef}
                type="file"
                hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleFileUploadOnClick}
              />
              <IconButton
                onClick={handleReplaceUploadedFile}
                sx={relpaceAndDeleteFileBtnStyle}
              >
                <ReplaceIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteUploadedFile}
                sx={relpaceAndDeleteFileBtnStyle}
              >
                <DeleteIcon sx={{ path: { fill: "#8A00E5" } }} />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ marginTop: "24px", marginBottom: "24px" }}>or</Divider>
        <Typography variant="body2" marginLeft="32px" marginBottom="32px">
          {localized["enter-config-details"]}
        </Typography>

        <Box>
          {selectedFormFields?.sections?.map((section) => (
            <Accordion
              key={section.title}
              defaultExpanded
              sx={{
                margin: "32px",
                ".MuiAccordionDetails-root": { padding: "0px 0px 0px 23px" },
                ".MuiAccordionSummary-root": { minHeight: "0px" },
                ".MuiAccordionSummary-content": { margin: "0px" },
              }}
            >
              <AccordionSummary
                sx={{ padding: "0px 0px 16px 0px !important" }}
                data-testid={section.title}
              >
                <Stack direction={"row"} width={"100%"}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#5D596E",
                      fontSize: "14px",
                      textAlign: "justify",
                    }}
                  >
                    {section.title}
                  </Typography>
                  <Divider
                    sx={{
                      flexGrow: 1,
                      marginBottom: "10px",
                      marginLeft: "10px",
                      color: "#EAEAEA",
                    }}
                  />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {section.title === "Subscribe nodes" ? (
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        marginTop: "24px",
                        marginBottom: "24px",
                        gap: "16px",
                        maxWidth: "calc(100vw - 614px)",
                      }}
                    >
                      <Typography
                        marginLeft="8px"
                        marginBottom="8px"
                        variant="overline"
                        sx={{
                          color: "#959595",
                          fontWeight: "700",
                          textTransform: "none",
                        }}
                      >
                        {tableData && tableData.length > 0
                          ? " "
                          : localized["start-by-creating-a-new-signal-node"]}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <Button
                          data-testid="addNodeBtn"
                          onClick={handleOpenDialog}
                          variant="outlined"
                          sx={{
                            minWidth: "148px",
                            height: "40px",
                            borderRadius: "24px",
                            padding: "8px 16px",
                          }}
                        >
                          <Typography
                            sx={{
                              variant: "h5",
                              textTransform: "none",
                              lineHeight: "24px",
                            }}
                          >
                            {localized["add-node-btn"]}
                          </Typography>
                        </Button>
                      </Box>
                    </Stack>
                    <SignalTable
                      tableData={tableData}
                      deleteNodeHandler={deleteNodeHandler}
                    />
                  </Box>
                ) : (
                  <Box display="flex" flexWrap="wrap" gap="24px">
                    {section.fields.map((field, fieldIndex) => (
                      <form>
                        <Box key={field.name}>
                          {renderField(field)}

                          {"unit" in field && (
                            <TextField
                              disabled
                              defaultValue={field.unit}
                              sx={{
                                ".MuiOutlinedInput-root": {
                                  width: "64px",
                                  height: "44px",
                                  color: "#000",
                                },
                                paddingLeft: "8px",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000",
                                },
                              }}
                            />
                          )}
                        </Box>
                      </form>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>

      <Box
        display="flex"
        sx={{
          gap: "32px",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "24px 32px",
        }}
      >
        <Button
          data-testid="cancelBtn"
          onClick={() => {
            if (isDirty || formDefaultValues) {
              setIsCancelConfirmationDialogOpen(true);
            } else {
              navigateToDevicesFn();
            }
          }}
          variant="outlined"
          sx={{
            minWidth: "148px",
            height: "40px",
            borderRadius: "24px",
            padding: "8px 16px",
          }}
        >
          <Typography
            sx={{
              variant: "h5",
              textTransform: "none",
              lineHeight: "24px",
            }}
          >
            {localized["cancel-btn"]}
          </Typography>
        </Button>

        <Button
          data-testid="saveBtn"
          disabled={!isValid || isSubmitted}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            minWidth: "152px",
            borderRadius: "24px",
            padding: "8px 16px",
          }}
        >
          <Typography
            sx={{
              variant: "h5",
              textTransform: "none",
              lineHeight: "24px",
            }}
          >
            {localized["save-btn"]}
          </Typography>
        </Button>
        <AddNodeDialog
          isOpen={dialogOpen}
          setIsOpen={setDialogOpen}
          addNodeHandler={addNodeHandler}
          tableData={tableData}
        />
      </Box>
      {isCancelConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={true}
          handleCloseDialog={() => {
            setIsCancelConfirmationDialogOpen(false);
          }}
          title={localized["unsaved-changes"]}
          description={localized["unsaved-changes-lost-confirmation"]}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-proceed"]}
          leftButtonClickHandler={() => {
            setIsCancelConfirmationDialogOpen(false);
          }}
          rightButtonClickHandler={navigateToDevicesFn}
          isWarning={false}
        />
      )}

      {isSubmitted && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress />  {/* Loader */}
        </Box>
      )}
    </Box>
  );
};

export default CommunicationProtocolForm;
