import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import localized from "../../../en.json";
import { SoftwareContext } from "../../../store/software-list-context";

const UploadedSoftwareCount = () => {
  const context = useContext(SoftwareContext);
  
  return (
    <Box
      display="grid"
      sx={{
        textAlign: "left",
        marginBottom: "12px",
        paddingLeft: "32px"
      }}
    >
      <Typography variant="h6" color="#1B1534">
        {localized["uploaded-software"]} ({context?.softwares?.length})
      </Typography>
    </Box>
  );
};

export default UploadedSoftwareCount;
