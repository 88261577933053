import moment from "moment-timezone";

export function convertDateTime(utcDateString: string | undefined): string {
  if (utcDateString) {
    const localDate = moment.utc(utcDateString).tz(moment.tz.guess());
    const timeZoneAbbr = localDate.format("z");

    return localDate.format("YYYY-MM-DD hh:mm A") + " " + timeZoneAbbr;
  }
  return "NA";
}
