import { gql, useApolloClient, useQuery } from "@apollo/client";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Node, Nodes } from "../../../../../../Models/models";
import localized from "../../../../../../en.json";
import { hasPermission } from "../../../../../../roleConfig";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import { RoleContext, RoleContextType } from "../../../../../Home/Home";

export const GET_DEVICE_DETAIL = gql`
  query ($deviceId: ID!) {
    getDeviceById(deviceId: $deviceId) {
      deviceStatus
    }
  }
`;
export const GET_NODES = gql`
  query getDeviceNodes($deviceId: ID!, $nodePath: String!) {
    getDeviceNodes(deviceId: $deviceId, nodePath: $nodePath) {
      name
      nodePath
      isChecked
      isBrowsable
      isEditable
      childNodes {
        name
      }
      unit
    }
  }
`;
export const UPDATE_SIGNALS = gql`
  mutation saveSignalsAndPublish(
    $deviceID: String!
    $signals: [SignalRequest]!
  ) {
    saveSignalsAndPublish(deviceID: $deviceID, signals: $signals) {
      id
    }
  }
`;
const StyledTreeItem = styled(TreeItem)(() => ({
  [`& .${treeItemClasses.label}`]: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    width: "auto !important",
    marginTop: 3,
    marginBottom: 3,
    height: "30px !important",
  },
}));
const SlowLinearProgress = styled(LinearProgress)({
  "& .MuiLinearProgress-bar": {
    // apply a new animation-duration to the `.bar` class
    animationDuration: "10s",
  },
});
let checkedNodes = new Map<string, { isChecked: boolean; unit: string }>();
let masterMap = new Map<string, { isChecked: boolean; unit: string }>();

function isLeafNode(node: Node) {
  return node && node.childNodes.length === 0;
}

function isNodeNonEditable(node: Node, roleContext: RoleContextType | null) {
  return (
    !node.isEditable || !hasPermission("signals.update", roleContext?.currRole)
  );
}

function renderUpdateSignalsButton(
  roleContext: RoleContextType | null,
  updateSignalsHandler: () => void,
  isNodesDirty: boolean
) {
  return (
    hasPermission("signals.update", roleContext?.currRole) && (
      <Button
        variant="contained"
        sx={{
          padding: "8px 16px",
          width: "180px",
          height: "40px",
          textTransform: "none",
          borderRadius: "24px",
          ":focus, :active, :enabled": {
            background: "#8A00E5",
          },
          "&:disabled": {
            backgroundColor: "#ADA9C2",
          },
        }}
        onClick={updateSignalsHandler}
        disabled={!isNodesDirty}
      >
        <Typography variant="h5" color="#fff">
          {localized["update-signals"]}
        </Typography>
      </Button>
    )
  );
}

function renderCheckbox(
  node: Node,
  roleContext: RoleContextType | null,
  setIsNodesDirty: (value: ((prevState: boolean) => boolean) | boolean) => void,
  nodes: Node[],
  deviceId: any
) {
  return !node.isBrowsable ? (
    <Checkbox
      value={node.nodePath}
      tabIndex={-1}
      disableRipple
      checked={node.isChecked}
      disabled={isNodeNonEditable(node, roleContext)}
      onChange={(event) => {
        setIsNodesDirty(
          changeIsChecked(
            nodes,
            node.nodePath,
            event.target.checked,
            deviceId,
            masterMap
          )
        );
      }}
    />
  ) : (
    <Box marginLeft="13px"></Box>
  );
}

function updateMasterMap(node: Node) {
  if (!masterMap.has(node.nodePath)) {
    masterMap.set(node.nodePath, {
      isChecked: node.isChecked,
      unit: node.unit,
    });
  }
}

function calculateDeltaForUpdateSignals(key: string) {
  if (checkedNodes.get(key)?.isChecked === masterMap.get(key)?.isChecked) {
    checkedNodes.delete(key);
  }
}

interface PropTypes {
  setIsDeviceConfigUpdated: Function;
}

export default function SignalMappingTree(props: PropTypes) {
  const { setIsDeviceConfigUpdated } = props;
  let { deviceId } = useParams();
  const [deviceStatus, setDeviceStatus] = useState("");

  const { loading, data } = useQuery<Nodes>(GET_NODES, {
    variables: {
      deviceId: deviceId,
      nodePath: "",
    },
    fetchPolicy: "no-cache",
  });
  const client = useApolloClient();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isNodesDirty, setIsNodesDirty] = useState(false);
  const roleContext = useContext(RoleContext);
  useEffect(() => {
    const getDeviceDetailById = async () => {
      client
        .query({
          query: GET_DEVICE_DETAIL,
          variables: {
            deviceId: deviceId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setDeviceStatus(response.data.getDeviceById.deviceStatus);
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-to-fetch-device-detail"],
            false,
            enqueueSnackbar
          );
        });
    };
    getDeviceDetailById();
  }, [deviceId, client, enqueueSnackbar]);

  useEffect(() => {
    if (data && nodes.length === 0) {
      setNodes([...data?.getDeviceNodes]);
    }
  }, [data, nodes.length]);
  useEffect(() => {
    checkedNodes = new Map();
    masterMap = new Map();
  }, []);

  const handleNodeClick = (event: any, node: Node) => {
    event.stopPropagation();
    if (isLeafNode(node)) {
      client
        .query({
          query: GET_NODES,
          variables: {
            deviceId: deviceId,
            nodePath: node.nodePath,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: any) => {
          const nodesCopy = [...nodes];
          addChildNodesToNodesArray(
            nodesCopy,
            node.nodePath,
            response.data.getDeviceNodes
          );
          setNodes([...nodesCopy]);
        });
    }
  };
  const renderLoader = () => {
    return (
      <Box marginLeft="-16px" marginRight="-16px">
        <SlowLinearProgress sx={{ padding: "0px" }} />
      </Box>
    );
  };
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const renderTree = (node: Node) => {
    updateMasterMap(node);
    return (
      <>
        <StyledTreeItem
          key={node.nodePath}
          nodeId={node.nodePath}
          onClick={(event) => handleNodeClick(event, node)}
          label={
            <>
              {renderCheckbox(
                node,
                roleContext,
                setIsNodesDirty,
                nodes,
                deviceId
              )}
              <Typography variant="h5">{node.name}</Typography>
            </>
          }
        >
          {node.childNodes.length > 0
            ? node.childNodes.map((childNode: any) => renderTree(childNode))
            : node.isBrowsable && renderLoader()}
        </StyledTreeItem>
      </>
    );
  };
  const updateSignalsHandler = () => {
    checkedNodes.forEach(function (value, key) {
      calculateDeltaForUpdateSignals(key);
    });
    const checkedNodesArray = Array.from(checkedNodes, ([key, value]) => ({
      path: key,
      ...value,
    }));
    client
      .mutate({
        mutation: UPDATE_SIGNALS,
        variables: {
          deviceID: deviceId,
          signals: checkedNodesArray,
        },
      })
      .then(() => {
        setIsDeviceConfigUpdated(true);
        ShowSnackbar(localized["signal-update-success"], true, enqueueSnackbar);
        setIsNodesDirty(false);

        checkedNodes.forEach(function (value, key) {
          masterMap.set(key, value);
        });

        checkedNodes = new Map();
      })
      .catch(() => {
        ShowSnackbar(localized["signal-update-failed"], false, enqueueSnackbar);
      });
  };

  if (loading) {
    return (
      <Box marginTop="20%" marginLeft="35%" data-testid="loading-container">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            radius: "4px",
            border: "1px solid #EAEAEA",
            mt: "24px",
            width: "88%",
          }}
        >
          <Stack
            marginTop="10px"
            data-testid="treeview-container"
            height="55vh"
            maxHeight="calc(100vh - 400px)"
            sx={{
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
          >
            {deviceStatus !== "UnConfigured" && (
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  onClick={() => setExpanded([])}
                  sx={{ marginRight: "32px" }}
                >
                  <Typography variant="caption">
                    {localized["collapse-all"]}
                  </Typography>
                </Button>
              </Stack>
            )}

            <TreeView
              aria-label="icon expansion"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                backgroundColor: "white",
                position: "relative",
                ".MuiTreeItem-content": {
                  width: "98%",
                },

                ".MuiLinearProgress-root": {
                  width: "98%",
                },
              }}
              onNodeToggle={handleToggle}
              expanded={expanded}
            >
              {deviceStatus === "UnConfigured" ? (
                <Typography
                  variant="overline"
                  sx={{
                    color: "#959595",
                    textTransform: "none",
                    fontWeight: "700",
                    padding: "16px",
                  }}
                >
                  {localized["configure-devices-before-signals"]}
                </Typography>
              ) : (
                nodes.map((node: Node) => renderTree(node))
              )}
            </TreeView>
          </Stack>
        </Box>
        <Stack direction={"row"} mt="18px" justifyContent={"flex-start"}>
          {renderUpdateSignalsButton(
            roleContext,
            updateSignalsHandler,
            isNodesDirty
          )}
        </Stack>
      </>
    );
  }
}

const addChildNodesToNodesArray = (
  arr: Node[],
  nodePath: string,
  children: Node[]
) => {
  arr.forEach((i: Node, index, self) => {
    if (
      i.nodePath === nodePath &&
      JSON.stringify(i.childNodes) !== JSON.stringify(children)
    ) {
      self[index] = { ...i, childNodes: [...i.childNodes, ...children] };
    } else {
      addChildNodesToNodesArray(i.childNodes, nodePath, children);
    }
  });
};

const changeIsChecked = (
  arr: Node[],
  nodePath: string,
  isChecked: boolean,
  deviceId: number,
  masterMapInput: Map<string, { isChecked: boolean; unit: string }>
) => {
  arr.forEach((i: Node, index, self) => {
    if (i.nodePath === nodePath) {
      self[index] = { ...i, isChecked: isChecked };
      checkedNodes.set(i.nodePath, { isChecked: isChecked, unit: i.unit });
    } else {
      changeIsChecked(
        i.childNodes,
        nodePath,
        isChecked,
        deviceId,
        masterMapInput
      );
    }
  });

  return !mapsAreEqual(checkedNodes, masterMapInput);
};

//this method iterates keys of source map and checks if values corresponding to that keys are same in both source and target map.
const mapsAreEqual = (
  m1: Map<string, { isChecked: boolean; unit: string }>,
  m2: Map<string, { isChecked: boolean; unit: string }>
) =>
  Array.from(m1.keys()).every(
    (key) => m1.get(key)?.isChecked === m2.get(key)?.isChecked
  );
