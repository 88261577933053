import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import localized from "../../../en.json";
import { Asset } from "../../../Models/models";
import ActiveNotifications from "./ActiveNotifications";


const boxStyle = {
  textAlign: "center",
  padding: "12px",
  paddingBottom: "20px",
  overflowWrap: "anywhere",
};
interface PropType {
  open: boolean;
  handleOpen: () => void;
  asset: Asset;
}

const statusIndicator = (status: string) => {
  let color = "rgba(0, 0, 0, 0.6)";
  if (status === "connected") {
    color = "#14DA79";
  }
  return color;
};

const renderAssetKpi = (
  capacityName: string,
  capacityUnit: string,
  capacityValue: string
) => {
  return (
    <Box sx={boxStyle}>
      <Typography variant="h5" textAlign="left" fontWeight="400">
        {capacityValue} {capacityUnit}
      </Typography>
      <Typography variant="body1" fontSize="12px" color="#5F5F5F">
        {capacityName}
      </Typography>
    </Box>
  );
};
const AssetDetailsDrawer = (props: PropType) => {
  const { id, name, capacity, status, devicesInAsset, capacityUnit } =
    props.asset;
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.handleOpen}
      sx={{
        ".MuiDrawer-paper": {
          width: "472px",
          marginTop: "80px",
          height: "calc(100vh - 80px)"
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        padding="24px"
        alignItems="center"
        gap="24px"
      >
        <Box display="flex" justifyContent="normal" alignItems="center">
          <Typography
            variant="h4"
            fontWeight="400"
            paddingRight="4px"
            sx={{ maxWidth: "352px", wordBreak: "break-word" }}
          >
            {name}
          </Typography>
          <WifiOutlinedIcon
            data-testid="connectionIcon"
            style={{ color: statusIndicator(status) }}
          />
        </Box>
        <IconButton
          data-testid="close"
          aria-label="close"
          onClick={props.handleOpen}
        >
          <CloseIcon
            style={{ height: "32px", width: "32px", color: "#000000" }}
          />
        </IconButton>
      </Box>
      <Box sx={{ overflowY: "auto", scrollbarWidth: "thin" }}>
        <Box
          sx={{
            margin: "24px",
            width: "89%",
            borderRadius: "8px",
            border: "1px solid #DEDEDE",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {renderAssetKpi(
              localized["installed-capacity"],
              capacityUnit,
              capacity
            )}
            {renderAssetKpi(localized["actual-generation"], "KW", "2.45")}
            {renderAssetKpi(localized["actual-load-demand"], "KW", "2.45")}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {renderAssetKpi(localized["daily-specific-yield"], "KW", "2.45")}
            {renderAssetKpi(localized["plant-load-factor"], "KW", "2.45")}
            {renderAssetKpi(
              localized["plant-availability-factor"],
              "KW",
              "2.45"
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/asset-monitoring/${id}/projectsld`);
            }}
            sx={{
              textAlign: "center",
              textTransform: "none",
              borderRadius: "24px",
              width: "90%",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            {localized["view-details"]}
          </Button>
        </Box>

        <Box margin="24px">
          <Accordion
            data-testid="accordian"
            sx={{
              boxShadow: "none",
              width: "424px",
              border: "1px solid #DEDEDE",
              borderRadius: "8px !important",
              ".MuiAccordionDetails-root": {
                padding: "0px !important",
              },
            }}
          >
            <AccordionSummary
              disabled={devicesInAsset?.length === 0}
              sx={{
                fontSize: "18px",
              }}
              expandIcon={
                <ExpandMoreIcon
                  style={{ height: "24px", width: "24px", color: "#000" }}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {localized["edge-devices-connected"]} ({devicesInAsset?.length})
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{ ".MuiListItem-root": { padding: "0px !important" } }}>
                {devicesInAsset?.map((device, index) => (
                  <div key={device.id}>
                    <ListItem>
                      <ListItemText
                        sx={{
                          padding: "8px 16px",
                          marginRight: "8px",
                          ".MuiTypography-root": {
                            fontSize: "16px",
                          },
                        }}
                        primary={device.name}
                      />
                      <ListItemIcon sx={{ minWidth: "40px !important" }}>
                        <IconButton>
                          <ArrowForwardIosIcon
                            style={{
                              height: "12px",
                              width: "12px",
                              color: "#000",
                            }}
                          />
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                    {index < devicesInAsset.length - 1 && <Divider />}
                  </div>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
        <ActiveNotifications />
      </Box>
    </Drawer>
  );
};

export default AssetDetailsDrawer;
