import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import WestIcon from "@mui/icons-material/West";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import localized from "../../../../../en.json";
import { assetTypes, handleNumberInputKeyDown, units } from "../../../../../util/ConstantUtils";
import ShowSnackbar from "../../../../CustomizedSnackbar/ShowSnackbar";
import PvTechnicalConfigurationForm from "./PvTechnicalConfigurationForm";
import { regex } from "../../../ProjectManagement/SitesDetails/CreateSite/CreateSiteDialog";
import { AssetEdit } from "../../../../../Models/models";

interface TechnicalParameter {
  name: string;
  value: string;
  unit: string;
}

interface AssetForm {
  assetName: string;
  assetType: string;
  capacity: string;
  capacityUnit: string;
  latitude: string | number;
  longitude: string | number;
}

export const CREATE_ASSET = gql`
  mutation (
    $name: String!
    $type: String!
    $capacity: Float!
    $capacityUnit: String!
    $latitude: Float!
    $longitude: Float!
    $siteID: Int!
    $technicalConfiguration: [AssetTechnicalConfigurationInput]
  ) {
    createAsset(
      assetDtoReq: {
        name: $name
        type: $type
        capacity: $capacity
        capacityUnit: $capacityUnit
        latitude: $latitude
        longitude: $longitude
        siteID: $siteID
        technicalConfiguration: $technicalConfiguration
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation (
    $id: Int!
    $name: String!
    $type: String!
    $capacity: Float!
    $capacityUnit: String!
    $latitude: Float!
    $longitude: Float!
    $siteID: Int!
    $technicalConfiguration: [AssetTechnicalConfigurationInput]
  ) {
    updateAsset(
      assetDtoReq: {
        id: $id
        name: $name
        type: $type
        capacity: $capacity
        capacityUnit: $capacityUnit
        latitude: $latitude
        longitude: $longitude
        siteID: $siteID
        technicalConfiguration: $technicalConfiguration
      }
    ) {
      id
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query ($assetID: Int!) {
    getAssetById(assetID: $assetID) {
      id
      name
      type
      capacity
      capacityUnit
      latitude
      longitude
      technicalConfiguration {
        name
        value
        unit
      }
    }
  }
`;
export const CreateAsset = () => {
  const [renderPvConfigurationForm, setRenderPvConfigurationForm] =
    useState(false);
  const [pvPanelFormdata, setPvPanelFormData] = useState();
  const [pvFormDisable, setPvFormDisable] = useState(false);
  const client = useApolloClient();
  let { siteId } = useParams();
  let { assetId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [assetDetails, setAssetDetails] = useState<AssetEdit>();
  const [initialValues, setInitialValues] = useState<FormValues | null>(null);
  const [initialTech, setInitialTech] = useState(null);
  const pvPanel = "PV Panel";
  type FormValues = z.infer<typeof schema>;

  const getAssetDetail = () => {
    client
      .query({
        query: GET_ASSET_BY_ID,
        variables: { assetID: assetId },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        setAssetDetails(response.data.getAssetById);
      })
      .catch(() => {
        ShowSnackbar(localized["failed-to-fetch-asset-details"], false, enqueueSnackbar);
      });
  };

  useEffect(() => {
    if (assetId) {
      getAssetDetail();
    }
  }, [assetId]);

  useEffect(() => {
    if (assetId && assetDetails) {
      setIsEditMode(true);
      const formValues = {
        assetName: assetDetails.name,
        assetType: assetDetails.type,
        capacityUnit: assetDetails.capacityUnit,
        capacity: assetDetails.capacity.toString(),
        latitude: assetDetails.latitude.toString(),
        longitude: assetDetails.longitude.toString(),
      };

      reset(formValues, { keepDirty: false });
      setInitialValues(formValues);
      setInitialTech(
        convertDataToInitialValues(assetDetails.technicalConfiguration) as any
      );
      if (assetDetails.technicalConfiguration.length) {
        setPvPanelFormData(
          convertDataToInitialValues(assetDetails.technicalConfiguration) as any
        );
      }

    }
  }, [assetId, assetDetails]);

  const convertDataToInitialValues = (technicalConfigData: any) => {
    const formData: Record<string, string> = {};
    technicalConfigData.forEach((param: TechnicalParameter) => {
      const { name, value, unit } = param;
      formData[name] = value;
      if (unit !== null) {
        formData[`${name}_unit`] = unit;
      }
    });
    return formData;
  };
  const schema = z.object({
    assetName: z
      .string()
      .min(1, { message: "Name is required" })
      .regex(regex, {
        message: localized["only-alphanumeric-allowed"],
      })
      .max(50, {
        message: "Name" + localized["name_max_validation"],
      }),
    assetType: z.string().min(1, { message: localized["required-field"] }),
    capacity: z
      .string()
      .min(1, { message: localized["required-field"] })
      .regex(/^\d*(\.\d+)?$/, {
        message: localized["no-negative-numbers"],
      }),
    capacityUnit: z.string().min(1, { message: localized["required-field"] }),
    latitude: z
      .string()
      .min(1, { message: localized["required-field"] })
      .refine(
        (value) => {
          const numberValue = parseFloat(value);
          return !isNaN(numberValue) && numberValue >= -90 && numberValue <= 90;
        },
        {
          message: localized["latitude-validation"],
        }
      ),
    longitude: z
      .string()
      .min(1, { message: localized["required-field"] })
      .refine(
        (value) => {
          const numberValue = parseFloat(value);
          return (
            !isNaN(numberValue) && numberValue >= -180 && numberValue <= 180
          );
        },
        {
          message: localized["longitude-validation"],
        }
      ),
  });
  const {
    register,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
    setValue,
  } = useForm<FormValues>({ mode: "onChange", resolver: zodResolver(schema) });

  useEffect(() => {
    let values = watch();
    if (values.assetType === pvPanel) {
      setRenderPvConfigurationForm(true);
    } else{
      setRenderPvConfigurationForm(false);
    }
  }, [watch().assetType]);

  const technicalConfigDetails = (
    assetDetailsForm: AssetForm, 
    technicalConfigForm: any
  ) => {
    return assetDetailsForm.assetType === pvPanel 
      ? createTechnicalConfiguration(technicalConfigForm) 
      : [];
  };
  const generateVariablesForCreateUpdate = (
    assetDetailsForm: AssetForm,
    technicalConfigForm: any
  ) => ({
    name: assetDetailsForm.assetName,
    type: assetDetailsForm.assetType,
    capacity: assetDetailsForm.capacity,
    capacityUnit: assetDetailsForm.capacityUnit,
    latitude: assetDetailsForm?.latitude,
    longitude: assetDetailsForm?.longitude,
    siteID: siteId,
    technicalConfiguration: technicalConfigDetails(assetDetailsForm, technicalConfigForm),
  });

  const createAssetHandler = (
    assetDetailsForm: AssetForm,
    technicalConfigForm: any,
    isSaveAndClose: boolean
  ) => {
    client
      .mutate({
        mutation: CREATE_ASSET,
        variables: generateVariablesForCreateUpdate(assetDetailsForm, technicalConfigForm),
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.createAsset.id) {
          reset();
          setRenderPvConfigurationForm(false);
          if(isSaveAndClose){
            navigate(`/engineering/site/${siteId}/energyassets`);
          }
        }
        ShowSnackbar(
          localized["asset-creation-success-msg"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["asset-creation-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };

  const updateAssetHandler = (
    assetDetailsForm: AssetForm,
    technicalConfigForm: any
  ) => {
    client
      .mutate({
        mutation: UPDATE_ASSET,
        variables: {
          id: assetId,
          name: assetDetailsForm.assetName,
          type: assetDetailsForm.assetType,
          capacity: assetDetailsForm.capacity,
          capacityUnit: assetDetailsForm.capacityUnit,
          latitude: assetDetailsForm?.latitude,
          longitude: assetDetailsForm?.longitude,
          siteID: siteId,
          technicalConfiguration: technicalConfigDetails(assetDetailsForm, technicalConfigForm)
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.updateAsset.id) {
          reset();
          setRenderPvConfigurationForm(false);
        }
        ShowSnackbar(
          localized["asset-updation-success-msg"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["asset-updation-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };

  function createTechnicalConfiguration(
    data: Record<string, string>
  ): TechnicalParameter[] {
    const technicalConfiguration: TechnicalParameter[] = [];

    for (const key in data) {
      if (key.endsWith("_unit")) {
        continue;
      }
      const value = data[key];
      const unit = data[`${key}_unit`];
      technicalConfiguration.push({
        name: key,
        value,
        unit,
      });
    }

    return technicalConfiguration;
  }

  const saveAsset = (isSaveAndClose: boolean) => {
    createAssetHandler(watch(), pvPanelFormdata, isSaveAndClose);
  };

  const saveAssetAndClose = () => {
    if (!isEditMode) {
      saveAsset(true);
    } else {
      updateAssetHandler(watch(), pvPanelFormdata);
    }
  };

  const handleInputChange = (event: any, field: any, decimalPlaces: number) => {
    const { value } = event.target;
    const decimalIndex = value.indexOf(".");

    if (
      decimalIndex !== -1 &&
      value.length - decimalIndex - 1 > decimalPlaces
    ) {
      const truncatedValue = value.slice(0, decimalIndex + decimalPlaces + 1);
      setValue(field, truncatedValue);
      event.target.value = truncatedValue;
    }
  };

  const handleCancelBtn = () => {
    reset();
    setRenderPvConfigurationForm(false);
    navigate(`/engineering/site/${siteId}/energyassets`);
  };

  const handleBtnDisable = () => {
    if (watch().assetType !== pvPanel) {
      return !isValid || JSON.stringify(initialValues) === JSON.stringify(watch())
    }
    else {
      return (
        !isValid ||
        (!isDirty && Object.keys(errors).length > 0) ||
        pvFormDisable ||
        (JSON.stringify(initialValues) === JSON.stringify(watch()) &&
          JSON.stringify(initialTech) === JSON.stringify(pvPanelFormdata))
      );
    }

  };
  return (
    <>
      <Box>
        <Box
          sx={{ marginTop: "24px", marginLeft: "32px", marginBottom: "32px" }}
          display="flex"
          alignItems="center"
        >
          <IconButton sx={{ marginRight: "24px" }} onClick={handleCancelBtn}>
            <WestIcon
              fontSize="small"
              style={{ height: "24px", width: "24px", color: "#8A00E5" }}
            ></WestIcon>
          </IconButton>
          <Typography variant="h4" fontSize="22px">
            {localized["new-energy-asset"]}
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: "calc(100vh - 408px)",
            overflowY: "auto",
            scrollbarWidth: "thin",
            height: "calc(100vh - 408px)",
          }}
        >
          <Box marginLeft="32px">
            <Typography variant="body2" paddingBottom="16px">
              {localized["energy-asset-details"]}
            </Typography>
            <form>
              <Box display="flex" sx={{ gap: "24px" }}>
                <Box>
                  <InputLabel>
                    <Typography
                      variant="h5"
                      sx={{
                        padding: "2%",
                        color: "#000000",
                        span: {
                          color: "#DA1E28",
                        },
                      }}
                    >
                      {localized["asset-name"]} <span>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl sx={{ marginBottom: "16px" }}>
                    <TextField
                      id="name"
                      data-testid="assetName"
                      {...register("assetName")}
                      error={errors.assetName !== undefined}
                      helperText={errors.assetName?.message}
                      sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                      inputProps={{
                        style: {
                          width: "240px",
                          height: "11px",
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel id="select-placeholder">
                    <Typography
                      variant="h5"
                      sx={{
                        padding: "2%",
                        color: "#000000",
                        span: {
                          color: "#DA1E28",
                        },
                      }}
                    >
                      {localized["asset-type"]}
                      <span>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl sx={{ width: "240px" }}>
                    <Select
                      data-testid="select"
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "10px",
                          padding: "10px 8px",
                        },
                        "& .MuiSelect-select": {
                          paddingRight: "24px",
                        },
                      }}
                      {...register("assetType")}
                      value={watch("assetType") || ""}
                      onChange={(e) =>
                        setValue("assetType", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      defaultValue=""
                      error={errors.assetType !== undefined}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {localized["select"]}
                      </MenuItem>

                      {assetTypes.map((type) => (
                        <MenuItem
                          key={type}
                          value={type}
                        >
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.assetType?.message}</FormHelperText>
                  </FormControl>
                </Box>

                <Box>
                  <InputLabel>
                    <Typography
                      variant="h5"
                      sx={{
                        padding: "2%",
                        color: "#000000",
                        span: {
                          color: "#DA1E28",
                        },
                      }}
                    >
                      {localized["installed-capacity"]}
                      <span>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl sx={{ marginBottom: "16px" }}>
                    <TextField
                      data-testid="installedCapacity"
                      type="number"
                      onKeyDown={handleNumberInputKeyDown}
                      id="installedCapacity"
                      {...register("capacity")}
                      error={errors.capacity !== undefined}
                      helperText={errors.capacity?.message}
                      sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                      inputProps={{
                        style: {
                          width: "240px",
                          height: "11px",
                        },
                        step: "0.001",
                        onInput: (event: any) => {
                          handleInputChange(event, "capacity", 3);
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <FormControl
                  sx={{
                    marginTop: "33.5px",
                    width: "120px",
                    marginLeft: "-16px",
                  }}
                >
                  <Select
                    {...register("capacityUnit")}
                    value={watch("capacityUnit") || ""}
                    onChange={(e) =>
                      setValue("capacityUnit", e.target.value, {
                        shouldValidate: true,
                      })
                    }
                    defaultValue=""
                    displayEmpty
                    sx={{
                      "& .MuiInputBase-input": {
                        minHeight: "24px",
                        height: "10px",
                        padding: "10px 8px",
                      },
                      "& .MuiSelect-select": {
                        paddingRight: "24px",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {localized["unit"]}
                    </MenuItem>

                    {units.map((units) => (
                      <MenuItem key={units} value={units}>
                        {units}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.capacityUnit?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display="flex">
                <Box>
                  <InputLabel htmlFor="latitude">
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "8px",
                        color: "#15131F",
                        span: {
                          color: "#DA1E28",
                        },
                      }}
                    >
                      {localized["latitude"]} ( ° ) <span>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl sx={{ marginBottom: "16px" }}>
                    <TextField
                      data-testid="latitude"
                      id="latitude"
                      {...register("latitude")}
                      type="number"
                      onKeyDown={handleNumberInputKeyDown}
                      error={errors.latitude !== undefined}
                      helperText={errors.latitude?.message}
                      sx={{
                        ".MuiOutlinedInput-root": {
                          width: "240px",
                          marginRight: "24px",
                        },
                      }}
                      inputProps={{
                        style: {
                          width: "240px",
                          height: "11px",
                        },
                        step: "0.00000001",
                        onInput: (event: any) => {
                          handleInputChange(event, "latitude", 8);
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel htmlFor="longitude">
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "8px",
                        color: "#15131F",
                        span: {
                          color: "#DA1E28",
                        },
                      }}
                    >
                      {localized["longitude"]} ( ° ) <span>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl sx={{ marginBottom: "16px" }}>
                    <TextField
                      data-testid="longitude"
                      id="longitude"
                      {...register("longitude")}
                      type="number"
                      onKeyDown={handleNumberInputKeyDown}
                      error={errors.longitude !== undefined}
                      helperText={errors.longitude?.message}
                      sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                      inputProps={{
                        style: {
                          width: "240px",
                          height: "11px",
                        },
                        step: "0.00000001",
                        onInput: (event: any) => {
                          handleInputChange(event, "longitude", 8);
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </form>
          </Box>
          {renderPvConfigurationForm && (
            <PvTechnicalConfigurationForm
              assetType={watch().assetType}
              setPvPanelFormData={setPvPanelFormData}
              setPvFormDisable={setPvFormDisable}
              initialValues={pvPanelFormdata}
            />
          )}
        </Box>

        <Box
          display="flex"
          sx={{
            gap: "32px",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "24px 32px",
          }}
        >
          <Button
            onClick={handleCancelBtn}
            variant="outlined"
            sx={{
              width: "152px",
              height: "40px",
              borderRadius: "24px",
              padding: "8px 16px",
            }}
          >
            <Typography
              sx={{
                variant: "h5",
                textTransform: "none",
                lineHeight: "24px",
              }}
            >
              {localized["cancel-btn"]}
            </Typography>
          </Button>

          {!isEditMode && (
            <Button
              data-testid="saveCreateBtn"
              disabled={handleBtnDisable()}
              onClick={()=>{saveAsset(false)}}
              variant="outlined"
              sx={{
                borderRadius: "24px",
                padding: "8px 16px",
              }}
            >
              <Typography
                sx={{
                  variant: "h5",
                  textTransform: "none",
                  lineHeight: "24px",
                }}
              >
                {localized["save-create-another-btn"]}
              </Typography>
            </Button>
          )}

          <Button
            data-testid="saveCloseBtn"
            disabled={handleBtnDisable()}
            onClick={saveAssetAndClose}
            variant="contained"
            sx={{
              minWidth: "152px",
              borderRadius: "24px",
              padding: "8px 16px",
            }}
          >
            <Typography
              sx={{
                variant: "h5",
                textTransform: "none",
                lineHeight: "24px",
              }}
            >
              {isEditMode ? localized["save-btn"] : localized["save-close-btn"]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};
