import { ApolloError, gql, useApolloClient } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { PlotRelayoutEvent } from "plotly.js";
import React, { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import localized from "../../../../en.json";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import "./Charts.css";
import { ParameterFilter } from "./ParameterFilter";
import { TimeFilter } from "./TimeFilter";
import { SignalNameAndPath } from "../../../../Models/models";

export const SIGNAL_VALUES = gql`
  query (
    $signalsSelected: [String]!
    $startDate: Float!
    $endDate: Float!
    $deviceId: String!
  ) {
    fetchSignalsAggregateValues(
      signalAggregateDataRequest: {
        deviceId: $deviceId
        signalsNodePath: $signalsSelected
        startTime: $startDate
        endTime: $endDate
      }
    ) {
      nodePath
      aggregateValues {
        values
        timestamp
      }
    }
  }
`;
export const GET_SIGNAL_LIST = gql`
  query ($deviceId: String!) {
    getSignalsByDeviceId(deviceId: $deviceId) {
      path
      displayName
      unit
    }
  }
`;
export const DOWNLOAD_EXCEL = gql`
  query (
    $deviceId: String!
    $signalsNodePath: [String]!
    $startTime: Float!
    $endTime: Float!
    $timezone: String!
  ) {
    downloadExcelOfSignals(
      signalDataRequest: {
        deviceId: $deviceId
        signalsNodePath: $signalsNodePath
        startTime: $startTime
        endTime: $endTime
        timezone: $timezone
      }
    ) {
      url
    }
  }
`;
const colors = [
  "#FF00FF",
  "#FF0000",
  "#9932CC",
  "#B67D71",
  "#00318B",
  "#A2A2D0",
  "#C6AE00",
  "#978C8A",
  "#3CBAC9",
  "#AC5B85",
];

interface PropsType {
  deviceId: string | undefined;
  signalsSelected: string[];
  startTime: Date;
  endTime: Date;
}

const downloadIcon = {
  width: 320,
  height: 500,
  path: "M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z",
};

export const Chart = (props: PropsType) => {
  const [currentDate] = useState<Date>(new Date());
  const [singleYAxis, setSingleYAxis] = useState<any>(false);
  const [startTime, setStartTime] = useState<Date>(props.startTime);
  const [endTime, setEndTime] = useState<Date>(props.endTime);
  const [layout, setLayout] = useState<any>({});
  const [graphData, setGraphData] = useState<any>();
  const [signalsSelected, setSignalsSelected] = React.useState<string[]>(
    props.signalsSelected
  );
  
  const [fileName, setFileName] = React.useState<string>(
    moment(currentDate).format("DD-MM-YYYY,hh:mm:ss").toString()
  );
  const [signalList, setSignalList] = useState<SignalNameAndPath[]>([]);
  const [signalListLoading, setSignalListLoading] = useState<boolean>(true);

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const selectedSignalsRef = useRef<string[]>([]);
  const startTimeRef = useRef<Date>(new Date());
  const endTimeRef = useRef<Date>(new Date());
  const fileNameRef = useRef<string>();
  selectedSignalsRef.current = signalsSelected;
  startTimeRef.current = startTime;
  endTimeRef.current = endTime;
  fileNameRef.current = fileName;
  useEffect(() => {
    fetchSignalList();
  }, [props.deviceId]);
  const getUpdatedProjectList = () => {
    setLoading(true);
    client
      .query({
        query: SIGNAL_VALUES,
        variables: {
          startDate: startTime.getTime(),
          endDate: endTime.getTime(),
          signalsSelected: signalsSelected,
          deviceId: props.deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((err: ApolloError) => {
       setLoading(false);
        ShowSnackbar(
          localized["failed-fetch-signalValues"] + err.message,
          false,
          enqueueSnackbar
        );
      });
  };

  const fetchSignalList = () => {
    setSignalListLoading(true); 
    client
      .query({
        query: GET_SIGNAL_LIST,
        variables: {
          deviceId: props.deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        const signals = response.data.getSignalsByDeviceId;
        signals.sort((a: SignalNameAndPath, b: SignalNameAndPath) =>
          a.displayName.localeCompare(b.displayName)
        );

        setSignalList(signals);
        setSignalListLoading(false); 
      })
      .catch((err: ApolloError) => {
        ShowSnackbar(
          localized["failed-fetch-signalList"] + err.message,
          false,
          enqueueSnackbar
        );
        setSignalListLoading(false);
      });
  };
  const fetchUpdatedSignalData = async () => {
    getUpdatedProjectList();
  };
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSingleYAxis(event.target.checked);
  };
  useEffect(() => {
    if (!loading && !signalListLoading) {
      fetchUpdatedSignalData();
    }
  }, [signalsSelected, startTime, endTime, singleYAxis, signalListLoading]);

  useEffect(() => {
    if (data && !signalListLoading) {
      renderChart();
    }
    customFileName();
  }, [data, signalsSelected, startTime, endTime, singleYAxis, signalListLoading]);

  const renderChart = () => {
    const initialLayout = {
      xaxis: {
        range: [startTime, endTime],
        showline: true,
        uirevision: "time",
        title: localized["x_axis_title"],
        titlefont: {
          size: 18,
        },
        domain: [0, 1],
      },
      yaxis: {
        range: [0, 100],
        autorange: true,
        zeroline: false,
        showline: true,
        title: localized["y_axis_title"],
        titlefont: {
          size: 18,
        },
      },
      showlegend: true,
      legend: { orientation: "h", y: 1.25, xanchor: "center", x: 0.5 },
    };
    let graphNode: string = "";
    if (data && singleYAxis) {
      const chartsData = data?.fetchSignalsAggregateValues;
      setLayout(initialLayout);
      plotSingleAxis(chartsData, setGraphData, signalList);
    } else if (data && !singleYAxis) {
      plotMultipleAxis(data, setLayout, setGraphData, signalList);
    } else {
      let gData: any[] = [];
      let timeArray: any[] = [startTime, endTime];
      let valueArray: any[] = [];

      gData.push({
        x: timeArray,
        y: valueArray,
        type: "scatter",
        name: graphNode,
        mode: "lines",
        line: {
          width: 2,
        },
      });
      setLayout(initialLayout);
      setGraphData(gData);
    }
  };
  const legendClickHandler = (event: any) => {
    if (!singleYAxis) {
      setLayout((prevLayout: any) => {
        const newLayout = { ...prevLayout };
        const yAx = `yaxis${
          event.curveNumber === 0 ? "" : event.curveNumber + 1
        }`;
        newLayout[yAx].visible =
          newLayout[yAx].visible != undefined ? !newLayout[yAx].visible : false;
        return newLayout;
      });
    }
    return true;
  };
  const customFileName = () => {
    if (signalsSelected.length < 2) {
      setFileName(
        "ISH_"
          .concat(
            signalList.find((signal) => signal.path === signalsSelected[0])
              ?.displayName || ""
          )
          .concat("_")
          .concat(
            moment(moment.utc(new Date().valueOf()))
              .format("YYYY_MM_DD_hh_mm_ss")
              .toString()
          )
      );
    } else {
      setFileName(
        "ISH_".concat(
          moment(new Date()).format("YYYY_MM_DD_hh_mm_ss").toString()
        )
      );
    }
  };

  const excelDownloadHandler = () => {
    ShowSnackbar(localized["excel-download-started"], true, enqueueSnackbar);
    client
      .query({
        query: DOWNLOAD_EXCEL,
        variables: {
          deviceId: props.deviceId,
          startTime: startTimeRef.current.getTime(),
          endTime: endTimeRef.current.getTime(),
          signalsNodePath: selectedSignalsRef.current,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        window.location.href = res.data.downloadExcelOfSignals.url;
        ShowSnackbar(
          localized["excel-download-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["failed-download-excel"] + "  " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };
  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
      }}
    >
      <Stack marginTop="0px">
        <Stack  border="1px solid #F0F0F0" borderRadius="8px" bgcolor={"white"}>
          <Stack
            width="calc(100vw - 520px)"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="16px"
          >
            <Box>
              <Typography
                variant="h5"
                color="secondary"
                component="span"
                mx="16px"
              >
                {localized["plots"]}
              </Typography>
              <TimeFilter setstartTime={setStartTime} setEndTime={setEndTime} isEventPage={false} />
            </Box>
            <Stack direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={singleYAxis}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    color="secondary"
                    component="span"
                  >
                    {localized["multiple_yaxis_text"]}
                  </Typography>
                }
              />
              <ParameterFilter
                setSignalsSelected={setSignalsSelected}
                selectedSignals={signalsSelected}
                deviceId={props.deviceId}
                signalList={signalList}
              />
            </Stack>
          </Stack>
          {loading && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                background: "#9747ff2b",
                zIndex: 1,
                width: "calc(100vw - 488px)",
                height: "calc(100vh - 368px)",
                position: "absolute",
              }}
            >
              <CircularProgress></CircularProgress>
            </Stack>
          )}
          <Plot
            data={graphData}
            layout={layout}
            onLegendClick={legendClickHandler}
            onRelayout={(event: PlotRelayoutEvent) => {
              const start = new Date(event["xaxis.range[0]"] || startTime);
              const startTimeOnRelayout = start.getTime();
              const end = new Date(event["xaxis.range[1]"] || endTime);
              const endTimeOnRelayout = end.getTime();
              const currentTime = new Date().getTime();
              if (
                (endTimeOnRelayout - startTimeOnRelayout) / 1000 > 10 &&
                endTimeOnRelayout <= currentTime
              ) {
                setStartTime(new Date(start));
                setEndTime(new Date(end));
              } else if (endTimeOnRelayout <= currentTime) {
                let layoutChanged = { ...layout };
                layoutChanged["xaxis"] = {
                  ...layoutChanged.xaxis,
                  range: [startTime, endTime],
                  fixedrange: true,
                };
                setLayout(layoutChanged);
              }
            }}
            config={{
              responsive: true,
              modeBarButtonsToRemove: ["resetScale2d", "autoScale2d", "zoom2d","pan2d"],
              displaylogo: false,
              toImageButtonOptions: {
                format: "png",
                filename: fileName,
                height: 500,
                scale: 1,
              },
              modeBarButtonsToAdd: [
                {
                  name: "downloadExcel",
                  title: "Download data as excel",
                  icon: downloadIcon,
                  click: (gd: any) => {
                    excelDownloadHandler();
                  },
                },
              ],
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
function plotMultipleAxis(
  data: any,
  setLayout: React.Dispatch<any>,
  setGraphData: React.Dispatch<any>,
  signalList: SignalNameAndPath[]
) {
  let gData: any[] = [];
  let graphNode: string = "";
  let indexLayoutForMultipleAxis = 0;
  let timeArray: any[] = [];
  let valueArray: any[] = [];
  let index = 1;
  const layoutNew: any = {
    showlegend: true,
    legend: { orientation: "h", y: 1.25, xanchor: "center", x: 0.5 },
    xaxis: {
      uirevision: "time",
      showline: true,
      autorange: true,
      title: "Time",
      titlefont: {
        size: 18,
        color: "#1B1534",
      },
      domain: [0, 1],
    },
  };
  const chartsData = data?.fetchSignalsAggregateValues;
  for (const nodePath in chartsData) {
    graphNode =
      (signalList.find(
        (signal) => signal.path === chartsData[nodePath].nodePath
      )?.displayName ?? "") +
      " (" +
      (signalList.find(
        (signal) => signal.path === chartsData[nodePath].nodePath
      )?.unit ?? "") +
      ")";
    layoutNew["xaxis"] = {
      ...layoutNew.xaxis,
      domain: [
        0.03 * indexLayoutForMultipleAxis,
        1 - 0.03 * indexLayoutForMultipleAxis,
      ],
    };
    createLayoutMutipleAxis(layoutNew, indexLayoutForMultipleAxis, graphNode);
    valueArray = [];
    timeArray = [];
    for (const aggregateValue in chartsData[nodePath].aggregateValues) {
      for (let value of chartsData[nodePath].aggregateValues[aggregateValue]
        .values) {
        timeArray.push(
          new Date(
            chartsData[nodePath].aggregateValues[aggregateValue].timestamp
          )
        );
        valueArray.push(value);
      }
    }
    gData.push({
      x: timeArray,
      y: valueArray,
      yaxis: `y${index}`,
      type: "scatter",
      name: graphNode,
      mode: "lines",
      line: {
        color: colors[indexLayoutForMultipleAxis],
        width: 2,
      },
    });
    index++;
    indexLayoutForMultipleAxis++;
  }
  setLayout(layoutNew);
  setGraphData(gData);
}

function createLayoutMutipleAxis(
  layoutNew: any,
  indexLayoutForMultipleAxis: number,
  graphNode: string
) {
  layoutNew[
    "yaxis" +
      `${
        indexLayoutForMultipleAxis === 0 ? "" : indexLayoutForMultipleAxis + 1
      }`
  ] = {
    title: {
      text: graphNode,
      standoff: 0,
    },
    automargin: true,
    autorange: true,
    fixedrange: true,
    overlaying: `${indexLayoutForMultipleAxis === 0 ? "" : "y"}`,
    side: `${indexLayoutForMultipleAxis % 2 != 0 ? "right" : "left"}`,
    position: `${
      indexLayoutForMultipleAxis == 0 || indexLayoutForMultipleAxis % 2 == 0
        ? 0.025 * indexLayoutForMultipleAxis
        : 1 - 0.025 * indexLayoutForMultipleAxis
    }`,
    anchor: `${indexLayoutForMultipleAxis % 2 != 0 ? "free" : ""}`,
    titlefont: {
      size: 16,
      color: colors[indexLayoutForMultipleAxis],
    },
    tickfont: { color: colors[indexLayoutForMultipleAxis] },
    showgrid: false,
    showline: true,
    zeroline: false,
    linecolor: colors[indexLayoutForMultipleAxis],
  };
}

function plotSingleAxis(
  chartsData: any,
  setGraphData: React.Dispatch<any>,
  signalList: SignalNameAndPath[]
) {
  let gData: any[] = [];
  let timeArray: any[] = [];
  let valueArray: any[] = [];
  let graphNode: string = "";
  for (const nodePath in chartsData) {
    valueArray = [];
    timeArray = [];
    graphNode =
      (signalList.find(
        (signal) => signal.path === chartsData[nodePath].nodePath
      )?.displayName ?? "") +
      (signalList.find(
        (signal) => signal.path === chartsData[nodePath].nodePath
      )?.unit ?? "");
    for (const aggregateValue in chartsData[nodePath].aggregateValues) {
      for (let value of chartsData[nodePath].aggregateValues[aggregateValue]
        .values) {
        timeArray.push(
          new Date(
            chartsData[nodePath].aggregateValues[aggregateValue].timestamp
          )
        );
        valueArray.push(value);
      }
    }
    gData.push({
      x: timeArray,
      y: valueArray,
      type: "scatter",
      name: graphNode,
      mode: "lines",
      line: {
        width: 2,
      },
    });

    setGraphData(gData);
  }
}
