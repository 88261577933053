import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import localized from "../../../../../en.json";
import { defaultValuesEdit } from "../../../../../Models/models";
import { DialogHeader } from "../../../../../util/DialogUtil";
import { handleNumberInputKeyDown } from "../../../../../util/ConstantUtils";

type CreateSiteDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: Function;
  createSiteHandler?: Function;
  editSiteHandler?: Function;
  defaultValues?: defaultValuesEdit;
};
const pattern: string =
  "^(?![\\s_-])[A-Za-z0-9](?:[A-Za-z0-9 _-]*[A-Za-z0-9])?\\s*$";

// Create a RegExp object with the 'i' flag for case-insensitive matching
export const regex: RegExp = new RegExp(pattern, "i");
export const CreateSiteDialog = (props: CreateSiteDialogProps) => {
  const schema = z.object({
    name: z
      .string()
      .min(1, { message: "Name is required" })
      .regex(regex, {
        message: localized["only-alphanumeric-allowed"],
      })
      .max(50, {
        message: "Name" + localized["name_max_validation"],
      }),
    description: z.string().max(300, {
      message: "Description" + localized["description_max_validation"],
    }),
    latitude: z.coerce
      .number()
      .gte(-90, localized["invalid-latitude"])
      .lte(90, "Value must be between -90 to 90")
      .optional(),
    longitude: z.coerce
      .number()
      .gte(-180, localized["invalid-longitude"])
      .lte(180, "Value must be between -180 to 180")
      .optional(),
    altitude: z.coerce
      .number()
      .gte(-500, localized["invalid-altitude"])
      .lte(10000, "Value must be between -500 to 10000")
      .optional(),
    weatherApi: z.string(),
  });
  type FormValues = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
    setValue,
    trigger,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: props.defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    reset();
    if (props.defaultValues) {
      props.editSiteHandler!(data);
    } else {
      props.createSiteHandler!(data);
    }
    props.setIsDialogOpen(false);
  };

  useEffect(() => {
    reset(props.defaultValues);
  }, [props.defaultValues, reset]);

  useEffect(() => {
    if (props.defaultValues) {
      for (const [key, value] of Object.entries(props.defaultValues)) {
        setValue(key as keyof FormValues, value);
      }
      trigger();
    }
  }, [props.defaultValues, setValue, trigger]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field:
      | "name"
      | "description"
      | "latitude"
      | "longitude"
      | "altitude"
      | "weatherApi",
    decimalPlaces: number
  ) => {
    const { value } = event.target;
    const decimalIndex = value.indexOf(".");

    if (
      decimalIndex !== -1 &&
      value.length - decimalIndex - 1 > decimalPlaces
    ) {
      const truncatedValue = value.slice(0, decimalIndex + decimalPlaces + 1);
      setValue(field, truncatedValue);
      event.target.value = truncatedValue;
      return;
    }
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const trimmedValue = value.replace(/^ +/, "");
    setValue("name", trimmedValue);
    event.target.value = trimmedValue;
    return;
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.isDialogOpen}
      fullWidth
      maxWidth="md"
      sx={{ border: "1px solid #EAEAEA", borderRadius: "8px" }}
    >
      <DialogHeader
        titleKey="site"
        defaultValues={props.defaultValues}
        localized={localized}
        onClose={() => { 
          props.setIsDialogOpen(false);
          reset();
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ padding: "32px" }}>
          <Stack direction="row" gap={"16px"} flexWrap={"wrap"}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="name">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {localized["name"]}
                  <span>*</span>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  error={errors.name !== undefined}
                  helperText={errors.name?.message}
                  sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                  inputProps={{
                    style: {
                      width: "240px",
                      height: "11px",
                    },
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleNameChange(event);
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel
                htmlFor="description"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {localized["description"]}
                </Typography>
                {watch("description")?.length || 0}/300
              </InputLabel>

              <FormControl sx={{ marginBottom: "20px" }}>
                <TextField
                  id="description"
                  placeholder={"Description"}
                  type="text"
                  {...register("description")}
                  error={errors.description !== undefined}
                  helperText={errors.description?.message}
                  inputProps={{
                    style: {
                      width: "548px",
                      height: "11px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" gap={"16px"} flexWrap={"wrap"}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="latitude">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {localized["latitude"]} ( ° )
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="latitude"
                  placeholder="Latitude"
                  type="number"
                  onKeyDown={handleNumberInputKeyDown}
                  {...register("latitude", {
                    setValueAs: (v) => (v === "" ? undefined : parseFloat(v)),
                  })}
                  error={errors.latitude !== undefined}
                  helperText={errors.latitude?.message}
                  sx={{ ".MuiOutlinedInput-root": { width: "267px" } }}
                  inputProps={{
                    style: {
                      width: "240px",
                      height: "11px",
                    },
                    step: "0.00000001",
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, "latitude", 8);
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="longitude">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {localized["longitude"]} ( ° )
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="longitude"
                  placeholder="Longitude"
                  type="number"
                  onKeyDown={handleNumberInputKeyDown}
                  {...register("longitude", {
                    setValueAs: (v) => (v === "" ? undefined : parseFloat(v)),
                  })}
                  error={errors.longitude !== undefined}
                  helperText={errors.longitude?.message}
                  sx={{ ".MuiOutlinedInput-root": { width: "267px" } }}
                  inputProps={{
                    style: {
                      width: "240px",
                      height: "11px",
                    },
                    step: "0.00000001",
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, "longitude", 8);
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="altitude">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {localized["altitude"]} ( m )
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="altitude"
                  placeholder="Altitude"
                  type="number"
                  onKeyDown={handleNumberInputKeyDown}
                  {...register("altitude", {
                    setValueAs: (v) => (v === "" ? undefined : parseFloat(v)),
                  })}
                  error={errors.altitude !== undefined}
                  helperText={errors.altitude?.message}
                  sx={{ ".MuiOutlinedInput-root": { width: "267px" } }}
                  inputProps={{
                    style: {
                      width: "240px",
                      height: "11px",
                    },
                    step: "0.001",
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, "altitude", 3);
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
          </Stack>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <InputLabel htmlFor="weatherApi">
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "8px",
                  color: "#15131F",
                }}
              >
                {localized["weather-api"]}
              </Typography>
            </InputLabel>
            <FormControl
              sx={{ marginBottom: "16px" }}
              error={errors.weatherApi !== undefined}
            >
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={"metblue"}
              >
                <FormControlLabel
                  key={"metblue"}
                  value="metblue"
                  control={<Radio {...register("weatherApi")} />}
                  label={
                    <Typography
                      variant="body1"
                      lineHeight={"26px"}
                      marginTop={"4px"}
                    >
                      {localized["met-blue-api-name"]}
                    </Typography>
                  }
                />
                <FormControlLabel
                  key={"solarwebservice"}
                  value="solarwebservice"
                  control={<Radio {...register("weatherApi")} />}
                  label={
                    <Typography
                      variant="body1"
                      lineHeight={"26px"}
                      marginTop={"4px"}
                    >
                      {localized["solar-web-service-api-name"]}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            alignItems: "center",
            padding: "32px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              width: "100%",
            }}
            type="submit"
            disabled={!(isDirty && isValid)}
          >
            {props.defaultValues
              ? localized["edit-button"]
              : localized["create-button"]}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export const getCloseIcon = (setIsDialogOpen: Function) => {
  return (
    <IconButton
      aria-label="close"
      onClick={() => {
        setIsDialogOpen(false);
      }}
      sx={{
        position: "absolute",
        right: 32,
        top: 24,
        color: "#0D0D0D",
      }}
    >
      <CloseIcon
        style={{
          width: "32px",
          height: "32px",
        }}
      />
    </IconButton>
  );
};
